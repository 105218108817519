import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Fade, Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { WID } from '../../constants/Language';
import { Word } from '../../containers';
import { makePath } from '../../lib';

const styles = theme => ({
  section: {
    width: '100%',
    backgroundColor: theme.palette.common.white
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.common.white
  },
  image: {
    display: 'block',
    width: '80%',
    margin: `${theme.spacing.unit * 8}px auto`,
  },
  gridRightBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    fontSize: 24,
    lineHeight: '1.5',
    margin: `${theme.spacing.unit * 2}px 0`,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
  },
  description: {
    lineHeight: '2',
    margin: `${theme.spacing.unit * 2}px 0`,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
  },
  buttonBox: {
    margin: `${theme.spacing.unit * 4}px 0`
  },
  button: {
    width: '100%',
    maxWidth: 150,
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing.unit * 4,
    color: theme.palette.primary.main,
    boxShadow: `2px 2px 0px 2px ${theme.palette.grey[300]}`,
    margin: theme.spacing.unit,
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
    }
  }
})

class OverviewSection extends React.Component {
  state = {
    loaded: false,
    visibleImage: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (wd, event) => {
    var elem, rect
    elem = document.getElementById('overview-image')
    if (elem === null) {
      return
    }
    rect = elem.getBoundingClientRect()
    if (this.state.visibleImage === false && rect.height * 1.0 - (window.innerHeight - rect.top) < 0) {
      this.setState({ visibleImage: true })
    }
  }

  handleLoadedImage = (event) => {
    this.setState({ loaded: true })
  }

  render() {
    const { classes, theme, width, language } = this.props
    const { loaded, visibleImage } = this.state
    const styles = {
      gridRightBox: {
        justifyContent: theme.breakpoints.values.sm < width ? undefined : 'center',
        marginLeft: theme.breakpoints.values.sm < width ? theme.spacing.unit * 5 : undefined,
      }
    }
    
    return(
      <div className={classes.section}>
        <Fade in={loaded && visibleImage} timeout={1000}>
          <div className={classes.content}>
            <Grid container>
              <Grid item xs={12}>
              </Grid>
              <Grid item sm={6} xs={12}>
                <img
                  className={classes.image}
                  src='https://base-ec2.akamaized.net/images/item/origin/55c5c2af9ce20a53c0ca66c04b9b20b8.jpg'
                  alt='overview'
                  id='overview-image'
                  onLoad={this.handleLoadedImage}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className={classes.gridRightBox} style={styles.gridRightBox}>
                  <div>
                    <Typography variant='h6' className={classes.title}>
                      <Word wid={WID.HOME_PAGE.OVERVIEW.SUBJECT}/>
                    </Typography>
                    <Typography className={classes.description}>
                      <Word wid={WID.HOME_PAGE.OVERVIEW.INTRODUCTION}/>
                    </Typography>
                    <div className={classes.buttonBox}>
                      <Button variant='outlined' className={classes.button} component={Link} to={makePath('/rule', language)}><Word wid={WID.HOME_PAGE.OVERVIEW.RULE}/></Button>
                      <Button variant='outlined' className={classes.button} component={Link} to={makePath('/play', language)}><Word wid={WID.HOME_PAGE.OVERVIEW.PLAY}/></Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(OverviewSection)
