import React from 'react'

export const WID = {
  GLOBAL_NAVIGATION: {
    HOME: 'global_navigation/home',
    RULE: 'global_navigation/rule',
    PLAY: 'global_navigation/play',
    TOPICS: 'global_navigation/topics',
    GOODS: 'global_navigation/goods',
  },
  HOME_PAGE: {
    OVERVIEW: {
      SUBJECT: 'home_page/overview/subject',
      INTRODUCTION: 'home_page/overview/introduction',
      RULE: 'home_page/overview/rule',
      PLAY: 'home_page/overview/play'
    },
    TOPICS: {
      TITLE: 'home_page/topics/title',
      MORE: 'home_page/topics/more',
    },
    GOODS: {
      TITLE: 'home_page/goods/title',
      MORE: 'home_page/goods/more',
    }
  },
  RULE_PAGE: {
    RULE_1: {
      TITLE: 'rule_page/rule_1/title',
      DESCRIPTION: 'rule_page/rule1/description',
    },
    RULE_2: {
      TITLE: 'rule_page/rule_2/title',
      DESCRIPTION: 'rule_page/rule2/description',
    },
    RULE_EX: {
      TITLE: 'rule_page/rule_ex/title',
      DESCRIPTION: 'rule_page/rule_ex/description',
      THREE_HEADER: 'rule_page/rule_ex/three_header',
      FOUR_HEADER: 'rule_page/rule_ex/four_header',
      FIVE_HEADER: 'rule_page/rule_ex/five_header',
    },
  },
  PLAY_PAGE: {
    SELECT_MODE_PAGE: {
      VS_CPU: 'play_page/select_mode_page/vs_cpu',
      PUZZLE: 'play_page/select_mode_page/puzzle',
      FREE_BOARD: 'play_page/select_mode_page/free_board',
    },
    VS_CPU_PAGE: {
      TITLE: 'play_page/vs_cpu_page/title',
      SELECT_PLAYER: 'play_page/vs_cpu_page/select_player',
      PLAYER_LEVEL_1: 'play_page/vs_cpu_page/player_level_1',
      PLAYER_LEVEL_2: 'play_page/vs_cpu_page/player_level_2',
      PLAYER_LEVEL_3: 'play_page/vs_cpu_page/player_level_3',
      PLAYER_LEVEL_4: 'play_page/vs_cpu_page/player_level_4',
      PLAYER_LEVEL_5: 'play_page/vs_cpu_page/player_level_5',
      SELECT_TURN: 'play_page/vs_cpu_page/select_turn',
      TURN_FIRST: 'play_page/vs_cpu_page/turn_first',
      TURN_SECOND: 'play_page/vs_cpu_page/turn_second',
      MINE: 'play_page/vs_cpu_page/mine',
      START: 'play_page/vs_cpu_page/start',
      RESULT_DIALOG: {
        RETRY: 'play_page/vs_cpu_page/result_dialog/retry',
        SELECT_PLAYER: 'play_page/vs_cpu_page/result_dialog/select_player',
        WATCH_BOARD: 'play_page/vs_cpu_page/result_dialog/watch_board',
      }
    },
    PUZZLE_PAGE: {
      TITLE: 'play_page/puzzle_page/title',
      SELECT_PUZZLE: 'play_page/puzzle_page/select_puzzle',
      NYANKO_PUZZLE: 'play_page/puzzle_page/nyanko_puzzle',
    },
    NYANKO_PUZZLE_PAGE: {
      TITLE: 'play_page/nyanko_puzzle_page/title',
      SELECT_TYPE: 'play_page/nyanko_puzzle_page/select_type',
      TIME_ATTACK: 'play_page/nyanko_puzzle_page/time_attack',
      SELECT_CAT: 'play_page/nyanko_puzzle_page/select_cat',
      BLACK_CAT: 'play_page/nyanko_puzzle_page/black_cat',
      WHITE_CAT: 'play_page/nyanko_puzzle_page/white_cat',
      START: 'play_page/nyanko_puzzle_page/start',
      CORRECT_NUMBER: 'play_page/nyanko_puzzle_page/correct_number',
      TIME: 'play_page/nyanko_puzzle_page/time',
      CORRECT: 'play_page/nyanko_puzzle_page/correct',
      INCORRECT: 'play_page/nyanko_puzzle_page/incorrect',
      RESULT: {
        TITLE: 'play_page/nyanko_puzzle_page/result/title',
        CORRECT: 'play_page/nyanko_puzzle_page/result/correct',
        INCORRECT: 'play_page/nyanko_puzzle_page/result/incorrect',
        RANK: 'play_page/nyanko_puzzle_page/result/rank',
        RETRY: 'play_page/nyanko_puzzle_page/result/retry',
        WATCH: 'play_page/nyanko_puzzle_page/result/watch',
      }
    },
    FREE_BOARD_PAGE: {
      TITLE: 'play_page/free_board_page/title',
      BACK: 'play_page/free_board_page/back',
      NEXT: 'play_page/free_board_page/next',
    }
  },
  TOPICS_PAGE: {
    HEADER: 'topics_page/header',
    TAGS: {
      NEWS: 'topics_page/tags/news',
      EVENT: 'topics_page/tags/event',
    }
  },
  GOODS_PAGE: {
    HEADER: 'goods_page/header',
  }
}

export const multipleWordsProps = [
  { wid: WID.GLOBAL_NAVIGATION.HOME,
    en: 'Home',
    ja: 'ホーム',
    cn: '首页',
    tw: '首页',
  },
  { wid: WID.GLOBAL_NAVIGATION.RULE,
    en: 'Rules',
    ja: 'ルール',
    cn: '规则',
    tw: '規則',
  },
  { wid: WID.GLOBAL_NAVIGATION.PLAY,
    en: 'Play',
    ja: 'あそぶ',
    cn: '游戏',
    tw: '遊玩',
  },
  { wid: WID.GLOBAL_NAVIGATION.TOPICS,
    en: 'Topics',
    ja: 'トピックス',
    cn: '新闻和活动',
    tw: '資訊與活動',
  },
  { wid: WID.GLOBAL_NAVIGATION.GOODS,
    en: 'Products',
    ja: 'グッズ',
    cn: '商品',
    tw: '商品',
  },
  { wid: WID.HOME_PAGE.OVERVIEW.RULE,
    en: 'Rules',
    ja: 'ルール',
    cn: '规则',
    tw: '規則',
  },
  { wid: WID.HOME_PAGE.OVERVIEW.SUBJECT,
    en: <React.Fragment>{'Cutie kitten\'s'}<br/>{'board game'}</React.Fragment>,
    ja: <React.Fragment>{'にゃんともかわいい'}<br/>{'ボードゲーム'}</React.Fragment>,
    cn: '',
    tw: '',
  },
  { wid: WID.HOME_PAGE.OVERVIEW.INTRODUCTION,
    en: <React.Fragment>{'Get a row of five kittens to win'}<br/>{'Simple rules anyone can play'}<br/>{'For company with parents and kids and friends'}</React.Fragment>,
    ja: <React.Fragment>{'にゃんこを５つならべたら勝ち'}<br/>{'だれでもすぐに遊べるシンプルなルール'}<br/>{'親子や友達との交流の場に'}</React.Fragment>,
    cn: '',
    tw: '',
  },
  { wid: WID.HOME_PAGE.OVERVIEW.PLAY,
    en: 'Try to play',
    ja: 'あそんでみる',
    cn: '',
    tw: '',
  },
  { wid: WID.HOME_PAGE.TOPICS.TITLE,
    en: 'Topics',
    ja: 'トピックス',
    cn: '',
    tw: '',
  },
  { wid: WID.HOME_PAGE.TOPICS.MORE,
    en: 'See more',
    ja: 'もっとみる',
    cn: '',
    tw: '',
  },
  { wid: WID.HOME_PAGE.GOODS.TITLE,
    en: 'Products',
    ja: 'グッズ',
    cn: '',
    tw: '',
  },
  { wid: WID.HOME_PAGE.GOODS.MORE,
    en: 'See more',
    ja: 'もっとみる',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_1.TITLE,
    en: 'Rule 1',
    ja: 'ルール １',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_1.DESCRIPTION,
    en: 'Take turns in putting a kitten on the board.',
    ja: 'にゃんこ を こうご に おいていきます。',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_2.TITLE,
    en: 'Rule 2',
    ja: 'ルール ２',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_2.DESCRIPTION,
    en: 'Get a row of five horizontally, vertically, or diagonally to win!',
    ja: 'たて、よこ、ななめ に ５つならんだら 勝ち！',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_EX.TITLE,
    en: 'Special rules',
    ja: '特別ルール',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_EX.DESCRIPTION,
    en: 'Say those special words when you play!',
    ja: '「にゃん語」を声にだしてプレイしてみよう！',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_EX.THREE_HEADER,
    en: '*When you got 3 of 5 kittens in a row',
    ja: '● ３つならんだら',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_EX.FOUR_HEADER,
    en: '*When you got 4 of 5 kittens in a row',
    ja: '● ４つならんだら',
    cn: '',
    tw: '',
  },
  { wid: WID.RULE_PAGE.RULE_EX.FIVE_HEADER,
    en: '*When you got 5 kittens in a row',
    ja: '● ５つならんだら',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.SELECT_MODE_PAGE.VS_CPU,
    en: 'VS CPU',
    ja: 'CPU対戦',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.SELECT_MODE_PAGE.PUZZLE,
    en: 'PUZZLE',
    ja: 'パズル',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.SELECT_MODE_PAGE.FREE_BOARD,
    en: 'FREE BOAED',
    ja: 'フリーボード',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.TITLE,
    en: 'VS CPU',
    ja: 'CPU対戦',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.SELECT_PLAYER,
    en: '1. Choose COM kitten',
    ja: '1. あいてをえらぶ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_1,
    en: 'A toddling kitten',
    ja: 'よちよちにゃんこ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_2,
    en: 'An alley kitten',
    ja: 'のらにゃんこ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_3,
    en: 'An offensive kitten',
    ja: 'せめにゃんこ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_4,
    en: 'A diffensive kitten',
    ja: 'まもりにゃんこ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_5,
    en: 'A boss cat',
    ja: 'ぼすにゃんこ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.SELECT_TURN,
    en: '2. Choose your turn',
    ja: '2. てばんをえらぶ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.TURN_FIRST,
    en: 'Black; first',
    ja: '先手（せんて）',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.TURN_SECOND,
    en: 'White; second',
    ja: '後手（ごて）',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.MINE,
    en: 'You',
    ja: 'あなた',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.START,
    en: 'Start to play!',
    ja: 'スタート！',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.RESULT_DIALOG.RETRY,
    en: 'Play again',
    ja: 'もういちど',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.RESULT_DIALOG.SELECT_PLAYER,
    en: 'Choose COM kitten',
    ja: 'あいてをえらぶ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.RESULT_DIALOG.WATCH_BOARD,
    en: 'Check the board',
    ja: 'ばんめんをみる',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.PUZZLE_PAGE.TITLE,
    en: 'PUZZLE',
    ja: 'パズル',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.PUZZLE_PAGE.SELECT_PUZZLE,
    en: 'Choose a puzzle',
    ja: 'パズルをえらぶ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.PUZZLE_PAGE.NYANKO_PUZZLE,
    en: 'Kitten puzzle',
    ja: 'にゃんこパズル',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.TITLE,
    en: 'Kitten puzzle',
    ja: 'にゃんこパズル',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.SELECT_TYPE,
    en: '1. Choose a type',
    ja: '1. タイプをえらぶ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.TIME_ATTACK,
    en: 'Time trial',
    ja: 'タイムアタック',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.SELECT_CAT,
    en: '2. Choose a kitten',
    ja: '2. にゃんこをえらぶ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.BLACK_CAT,
    en: 'Black kitten',
    ja: 'くろにゃんこ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.WHITE_CAT,
    en: 'White kitten',
    ja: 'しろにゃんこ',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.START,
    en: 'Start to play!',
    ja: 'スタート！',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.CORRECT_NUMBER,
    en: 'Correct answer',
    ja: '正解数',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.TIME,
    en: 'Time',
    ja: 'タイム',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.CORRECT,
    en: 'Correct',
    ja: '正解',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.INCORRECT,
    en: 'Incorrect',
    ja: '不正解',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.TITLE,
    en: 'Result',
    ja: '結果',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.CORRECT,
    en: 'Correct',
    ja: '正解',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.INCORRECT,
    en: 'Incorrect',
    ja: '不正解',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.RANK,
    en: 'Rank',
    ja: 'ランク',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.RETRY,
    en: 'Play again',
    ja: 'もういちど',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.WATCH,
    en: 'Check the board',
    ja: 'ばんめんをみる',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.FREE_BOARD_PAGE.TITLE,
    en: 'Free Board',
    ja: 'フリーボード',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.FREE_BOARD_PAGE.BACK,
    en: 'Back',
    ja: 'もどる',
    cn: '',
    tw: '',
  },
  { wid: WID.PLAY_PAGE.FREE_BOARD_PAGE.NEXT,
    en: 'Forward',
    ja: 'すすむ',
    cn: '',
    tw: '',
  },
  { wid: WID.TOPICS_PAGE.HEADER,
    en: 'Topics',
    ja: 'トピックス',
    cn: '',
    tw: '',
  },
  { wid: WID.TOPICS_PAGE.TAGS.NEWS,
    en: 'What\'s new',
    ja: 'おしらせ',
    cn: '',
    tw: '',
  },
  { wid: WID.TOPICS_PAGE.TAGS.EVENT,
    en: 'Events',
    ja: 'イベント',
    cn: '',
    tw: '',
  },
  { wid: WID.GOODS_PAGE.HEADER,
    en: 'Goods',
    ja: 'グッズ',
    cn: '',
    tw: '',
  }
]
