import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  cls1: {
    fill: '#040000',
  },
  cls2: {
    fill: '#fff',
  },
  cls3: {
    fill: '#fff',
    fillRule: 'evenodd',
  },
  cls4: {
    fill: '#231815',
  },
  cls5: {
    fill: '#070001',
  },
  cls6: {
    fill: '#4d4d4d',
  },
  cls7: {
    fill: '#e4007f',
  },
})

class RuleNo3 extends React.Component {
  render() {
    const { classes, type, width, height } = this.props
    const voice = 
      type === 'nya'    ? (<use xlinkHref="#rule3-nya" x='175' y='75'/>) :
      type === 'nyan'   ? (<use xlinkHref="#rule3-nyan" x='175' y='75'/>) :
      type === 'nyanko' ? (<use xlinkHref="#rule3-nyanko" x='165' y='75'/>) : undefined
    const viewBoxWidth =
      type === 'nya'    ? 580 :
      type === 'nyan'   ? 580 :
      type === 'nyanko' ? 580 : 580
    return (
      <svg viewBox={`0 0 ${viewBoxWidth} 260`} width={width} height={height}>
        <defs>
          <g id='rule3-black-cat'>
            <path className={classes.cls1} d='M128.41,160a50.35,50.35,0,0,0-11.21-23.89c1.07-4.51.56-10.47-.16-14.53-1.23-7-3.49-16.16-8-14.46-7.21,2.74-14.45,7.1-18.89,12.41a50.64,50.64,0,0,0-39.68,7.14c-6-3.54-14.38-5.2-22.18-5.31-4.79-.07-3.76,9.34-2.53,16.31.74,4.22,2.38,10.27,5.1,14.14a50.42,50.42,0,0,0-2.17,25.77c4.86,27.52,29.84,38.74,57.36,33.88S133.26,187.5,128.41,160Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M55.69,165.73a3.8,3.8,0,1,0,4.4,3.08A3.8,3.8,0,0,0,55.69,165.73Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M98.55,158.17a3.8,3.8,0,1,0,4.4,3.08A3.81,3.81,0,0,0,98.55,158.17Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M89.21,183.81c-1.46,1.59-2.91,1.55-4.59.29a10.63,10.63,0,0,1-2.33-2.45l0,0-.57-3.25s0,0,0,0a3.41,3.41,0,0,0,1.19-3.21c-.33-1.86-1.87-2.18-3.73-1.85s-3.34,1.18-3,3A3.39,3.39,0,0,0,78.44,179l.63,3.6c0,.09.05.16.07.24A10.41,10.41,0,0,1,78,185.27c-1.15,1.76-2.5,2.29-4.42,1.3s-3,2.13-1.14,3.09c4,2.06,7.33-.49,9.07-3.91,2.8,2.62,6.81,3.87,9.85.57a1.7,1.7,0,0,0,.19-2.32A1.66,1.66,0,0,0,89.21,183.81Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M122.48,176l-12.85,1.18c-2.11.2-1.53,3.44.57,3.24l12.85-1.18C125.16,179,124.57,175.75,122.48,176Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M109.33,175.35l12.39-3.6c2-.59,1.46-3.84-.57-3.24l-12.39,3.6C106.75,172.7,107.3,176,109.33,175.35Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M51.77,187.33l-12.48,3.29c-2,.53-1.48,3.77.57,3.24l12.48-3.29C54.37,190,53.82,186.79,51.77,187.33Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M51.44,185.56c2.11-.14,1.52-3.38-.57-3.24L38,183.17c-2.11.14-1.52,3.38.57,3.24Z' transform='translate(0 .49)' />
          </g>
          <g id='rule3-black-cat-piece'>
            <path className={classes.cls5} d='M75.78,27a27.34,27.34,0,0,0-9.46-12.29c.46-2.4-.28-5.52-1-7.64C64,3.41,61.77-1.37,58.72-.35,53.8,1.3,49,3.8,46.18,6.72A47.75,47.75,0,0,0,31.73,7a40.25,40.25,0,0,0-13.11,4.61c-4.47-1.69-10.5-2.33-16-2.17-3.39.1-2.06,5-.74,8.67a19.24,19.24,0,0,0,4.52,7.3,20.08,20.08,0,0,0,.12,13.65c5.21,14.37,23.57,19.57,42.7,16.24S81,41.35,75.78,27Z' transform='translate(0 .49)' />
            <path className={classes.cls6} d='M76.78,35.32C75.29,45.25,64.26,52.38,49.21,55c-19.13,3.34-37.49-1.87-42.7-16.24a20.08,20.08,0,0,1-.12-13.65,19.24,19.24,0,0,1-4.52-7.3A21.6,21.6,0,0,1,.49,12.31l-.41,8.9h0a6.48,6.48,0,0,0,0,2.15,17.7,17.7,0,0,0,1.09,4.16l.21.57a20.66,20.66,0,0,0,3.74,6.53l-.26,5.51c-.06.66-.1,1.33-.1,2l0,.5,0,.06A20.52,20.52,0,0,0,6,49.05c5.2,14.37,23.57,19.58,42.7,16.24C63.6,62.7,74.55,55.71,76.25,46h0v0a16,16,0,0,0,.19-3.81Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M24.79,32.07c-1.43.25-2.28,1.32-1.89,2.4A3,3,0,0,0,26.21,36c1.43-.25,2.28-1.32,1.89-2.4A3,3,0,0,0,24.79,32.07Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M54.58,26.87c-1.44.25-2.28,1.33-1.89,2.41a3,3,0,0,0,3.3,1.5c1.43-.26,2.28-1.33,1.89-2.41A3,3,0,0,0,54.58,26.87Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M49.63,40.65a2.57,2.57,0,0,1-3.22.28,7.28,7.28,0,0,1-1.81-1.22l0,0c-.2-.57-.41-1.13-.61-1.7,0,0,0,0,0,0a1.48,1.48,0,0,0,.63-1.73c-.35-1-1.45-1.09-2.75-.87s-2.28.72-1.93,1.69a2.29,2.29,0,0,0,1.81,1.33l.68,1.88.06.11a4.63,4.63,0,0,1-.66,1.36,2.38,2.38,0,0,1-3,.81c-1.39-.46-2,1.2-.61,1.66a4.93,4.93,0,0,0,6.15-2.32c2.15,1.3,5.06,1.85,7,0a.73.73,0,0,0,0-1.22A1.46,1.46,0,0,0,49.63,40.65Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M72.62,35.56l-9,1c-1.48.16-.86,1.85.61,1.69l9-1C74.71,37.09,74.09,35.4,72.62,35.56Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M63.29,35.62l8.52-2.25c1.38-.37.79-2.06-.61-1.69l-8.52,2.25C61.3,34.3,61.9,36,63.29,35.62Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M23.42,43.57l-8.61,2.08c-1.4.34-.8,2,.62,1.69L24,45.26C25.43,44.92,24.83,43.22,23.42,43.57Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M23.07,42.64c1.48-.13.86-1.82-.61-1.69l-9,.81c-1.48.13-.86,1.82.61,1.69Z' transform='translate(0 .49)' />
          </g>
          <g id='rule3-balloon'>
            <path className={classes.cls3} d='M143.24,170.21l13.11-31.59c-34.18-29.48-26.08-74.41,18.11-100.36s107.7-23.1,141.89,6.38S342.42,119,298.24,145c-32.61,19.15-77.28,23.18-112.88,10.19Z' transform='translate(0 .49)' />
          </g>
          <g id='rule3-nya'>
            <path className={classes.cls4} d='M1.94,34.85A14,14,0,0,1,1,32.36a33.47,33.47,0,0,1-.95-6.88,40.43,40.43,0,0,1,.23-6.89c.2-1.3.77-4.75,3.48-5a2.28,2.28,0,0,1,1,.11,3.14,3.14,0,0,1,2.34,3,22.86,22.86,0,0,1,.2,3.08c0,1.27.11,3.2.22,5.28.14,2.35.25,4.45.36,6.11a15.32,15.32,0,0,1,.1,1.9,3.32,3.32,0,0,1-1.7,3.51,2.74,2.74,0,0,1-1,.26A3.48,3.48,0,0,1,1.94,34.85ZM14.08,21.33c-3.59-.27-4.48-2-4.61-3.51s.44-3.33,3.93-4.26a30.73,30.73,0,0,1,4.25-.65,31.24,31.24,0,0,1,4.25-.1c3.6.32,4.48,2.07,4.61,3.52s-.44,3.37-3.93,4.26a26.73,26.73,0,0,1-4.2.68A24.19,24.19,0,0,1,14.08,21.33Zm5.06,13.85a16.16,16.16,0,0,1-4.77-.69,6.9,6.9,0,0,1-2-.9,3.71,3.71,0,0,1-1.53-4.41,3.46,3.46,0,0,1,1-1.44,4.87,4.87,0,0,1,2.31-.87,23.82,23.82,0,0,1,3.88-.09,6.12,6.12,0,0,1,1.21,0,28.09,28.09,0,0,1,5.63.44,3.5,3.5,0,0,1,2.7,2.33,3.39,3.39,0,0,1-.25,2.76,5.1,5.1,0,0,1-3.53,2.39l-2,.38C20.91,35.16,20,35.17,19.14,35.18Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M40.16,18.91c-.09-.57-.2-1.09-.24-1.5-.23-2.62,1.29-3.48,2-3.71a3.06,3.06,0,0,1,.72-.14c1-.09,2.15.06,3.65,2.57a.34.34,0,0,0,.33.14c.27-.09,1.28-.35,1.83-.48a.44.44,0,0,0,.29-.19,4.26,4.26,0,0,1,3.48-3.5,5.69,5.69,0,0,1,.87.06,3.91,3.91,0,0,1,3,4,.5.5,0,0,0,.13.23,7.16,7.16,0,0,1,3.88,5.14,6.94,6.94,0,0,1-1.7,6,5.4,5.4,0,0,1-3.6,2,3.14,3.14,0,0,1-3.09-1.6c-.64-1.47,0-2.63,1.08-4,.21-.27.33-.52.24-.72-.28-.5-1.14-.42-1.62-.38a6.37,6.37,0,0,0-1.88.41.29.29,0,0,0-.21.37A41.14,41.14,0,0,1,51,29a13.27,13.27,0,0,1,.44,2.25c.23,2.61-1.36,3.48-2,3.71a4.06,4.06,0,0,1-.86.14c-2.23.2-3.51-2.5-4.17-3.8-.69-1.46-1.45-3.4-2.19-5.49-.18-.47-.87-.06-1.17,0a4.18,4.18,0,0,1-.86.14,3.09,3.09,0,0,1-3-1.57c-.3-.7-.91-2.56,1.56-4.37.36-.27.82-.56,1.28-.84A.32.32,0,0,0,40.16,18.91Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M75.12,22.1a3.81,3.81,0,0,1-3.49-3.41,3.77,3.77,0,0,1,2.85-3.92,11.55,11.55,0,0,1,2.13-.64,55.17,55.17,0,0,1,5.76-.74,55.73,55.73,0,0,1,5.84-.27,10.69,10.69,0,0,1,2.2.25,3.86,3.86,0,0,1,3.49,3.37,3.88,3.88,0,0,1-2.85,4,11.85,11.85,0,0,1-2.13.61,49.51,49.51,0,0,1-5.79.78,45.5,45.5,0,0,1-5.81.23A11,11,0,0,1,75.12,22.1Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M121.5,15.89a9.64,9.64,0,0,1-.41,2c-.6,2-1.78,3-3.47,3.17s-3-.7-4-2.52a16.62,16.62,0,0,1-.69-1.92,35.32,35.32,0,0,1-1.26-5.27A34.57,34.57,0,0,1,111,6a7.83,7.83,0,0,1,0-2A4.86,4.86,0,0,1,115.8-.46a4.83,4.83,0,0,1,5.45,3.54,19.67,19.67,0,0,1,.34,2,38.81,38.81,0,0,1,.23,5.43A43.82,43.82,0,0,1,121.5,15.89Zm.85,10.16a4.27,4.27,0,1,1-4.64-3.9A4.27,4.27,0,0,1,122.35,26.05Z' transform='translate(0 .49)' />
          </g>
          <g id='rule3-nyan'>
            <path className={classes.cls4} d='M1.94,34.85A14.16,14.16,0,0,1,1,32.37a33.58,33.58,0,0,1-.95-6.89,40.43,40.43,0,0,1,.23-6.89c.2-1.3.77-4.75,3.48-5a2.28,2.28,0,0,1,1,.11,3.14,3.14,0,0,1,2.34,3,22.86,22.86,0,0,1,.2,3.08c0,1.27.11,3.2.22,5.28.14,2.35.25,4.45.37,6.11A17.5,17.5,0,0,1,8,33.11a3.32,3.32,0,0,1-1.7,3.51,2.74,2.74,0,0,1-1,.26A3.48,3.48,0,0,1,1.94,34.85ZM14.08,21.34c-3.59-.28-4.48-2-4.61-3.52s.44-3.33,3.93-4.26a30.73,30.73,0,0,1,4.25-.65,31.24,31.24,0,0,1,4.25-.1c3.6.32,4.49,2.07,4.61,3.52s-.44,3.37-3.93,4.26a26.73,26.73,0,0,1-4.2.68A25.28,25.28,0,0,1,14.08,21.34Zm5.06,13.84a16.16,16.16,0,0,1-4.77-.69,6.64,6.64,0,0,1-2-.9,3.71,3.71,0,0,1-1.53-4.41,3.53,3.53,0,0,1,1-1.44,4.86,4.86,0,0,1,2.31-.86,25,25,0,0,1,3.88-.1,6.12,6.12,0,0,1,1.21,0,28.09,28.09,0,0,1,5.63.44,3.5,3.5,0,0,1,2.7,2.33,3.42,3.42,0,0,1-.25,2.77,5.09,5.09,0,0,1-3.53,2.38l-2,.38C20.91,35.16,20,35.17,19.14,35.18Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M40.16,18.91c-.09-.57-.2-1.09-.24-1.5-.23-2.62,1.29-3.47,2-3.71a3.06,3.06,0,0,1,.72-.14c1-.09,2.16.06,3.65,2.57a.34.34,0,0,0,.33.14c.27-.09,1.28-.35,1.83-.47.14,0,.23-.13.3-.2a4.21,4.21,0,0,1,3.47-3.49,5.56,5.56,0,0,1,.87.05,3.91,3.91,0,0,1,3,4,.5.5,0,0,0,.13.23,7.16,7.16,0,0,1,3.88,5.14,6.94,6.94,0,0,1-1.7,6,5.35,5.35,0,0,1-3.6,2,3.15,3.15,0,0,1-3.09-1.6c-.64-1.47,0-2.63,1.08-4,.21-.27.33-.52.24-.72-.28-.5-1.14-.42-1.62-.38a6.44,6.44,0,0,0-1.88.41.29.29,0,0,0-.21.37A41.14,41.14,0,0,1,51,29a13.27,13.27,0,0,1,.44,2.25c.23,2.61-1.36,3.48-2,3.71a4.08,4.08,0,0,1-.85.14c-2.24.2-3.52-2.5-4.18-3.8-.69-1.46-1.45-3.4-2.19-5.49-.18-.47-.87-.06-1.17,0a4.18,4.18,0,0,1-.86.14,3.09,3.09,0,0,1-3-1.57c-.3-.7-.91-2.56,1.56-4.37.36-.27.82-.55,1.28-.84A.32.32,0,0,0,40.16,18.91Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M97.54,24.68a9.26,9.26,0,0,1-8.07,6.43,8.56,8.56,0,0,1-4.55-.82,9.07,9.07,0,0,1-2.66-1.92,14.83,14.83,0,0,1-1.17-1.45.28.28,0,0,0-.49,0c-1.29,1.88-3.73,4.9-6.66,5.16a3.1,3.1,0,0,1-2.4-.9,4.71,4.71,0,0,1-1.26-3.29A16,16,0,0,1,70.66,25a59.54,59.54,0,0,1,2.21-8A50.16,50.16,0,0,1,76.2,9.42c1.57-3,2.82-4.17,4.44-4.31a3.67,3.67,0,0,1,2,.48c2.27,1.4,1.64,4.89-1,10.63a.55.55,0,0,0,.45.76,3.8,3.8,0,0,1,3.06.94c1.32,1.17,1.65,5.38,3.72,5.2,1.27-.12,1.72-1.33,2-2.34.43-1.39,1-3,3.08-3.22A3.44,3.44,0,0,1,97,18.8a4.55,4.55,0,0,1,.83,2.29A9.37,9.37,0,0,1,97.54,24.68Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M121.5,15.89a9.64,9.64,0,0,1-.41,2c-.59,2-1.78,3-3.47,3.17s-3-.7-4-2.52a19,19,0,0,1-.68-1.92,33,33,0,0,1-1.26-5.27A34.7,34.7,0,0,1,111,6a8.32,8.32,0,0,1,0-2A4.86,4.86,0,0,1,115.8-.46a4.83,4.83,0,0,1,5.45,3.54,19.67,19.67,0,0,1,.34,2,38.67,38.67,0,0,1,.24,5.43A45.68,45.68,0,0,1,121.5,15.89Zm.85,10.16a4.27,4.27,0,1,1-4.64-3.9A4.23,4.23,0,0,1,122.35,26.05Z' transform='translate(0 .49)' />
          </g>
          <g id='rule3-nyanko'>
            <path className={classes.cls4} d='M1.83,35.59a12.16,12.16,0,0,1-.89-2.34,30.7,30.7,0,0,1-.89-6.48,37.88,37.88,0,0,1,.21-6.48c.19-1.22.72-4.47,3.28-4.69a2.28,2.28,0,0,1,1,.11A2.94,2.94,0,0,1,6.7,18.55a21.91,21.91,0,0,1,.19,2.89c0,1.2.1,3,.2,5,.13,2.21.24,4.19.34,5.75A15.59,15.59,0,0,1,7.53,34a3.13,3.13,0,0,1-1.61,3.3,2.39,2.39,0,0,1-1,.25A3.26,3.26,0,0,1,1.83,35.59ZM13.24,22.87C9.86,22.61,9,21,8.91,19.56s.41-3.13,3.69-4a28.1,28.1,0,0,1,4-.61,29.07,29.07,0,0,1,4-.09c3.39.29,4.22,1.94,4.34,3.3s-.41,3.17-3.7,4a24,24,0,0,1-3.95.63A22.34,22.34,0,0,1,13.24,22.87ZM18,35.9a15.48,15.48,0,0,1-4.5-.65,6.32,6.32,0,0,1-1.87-.85,3.5,3.5,0,0,1-1.44-4.15,3.36,3.36,0,0,1,1-1.35,4.5,4.5,0,0,1,2.18-.81A23.27,23.27,0,0,1,17,28a7.23,7.23,0,0,1,1.14,0,27,27,0,0,1,5.29.42A3.26,3.26,0,0,1,26,30.6a3.17,3.17,0,0,1-.23,2.6,4.85,4.85,0,0,1-3.33,2.25l-1.86.36C19.67,35.88,18.82,35.89,18,35.9Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M37.76,20.6c-.08-.55-.19-1-.23-1.42-.21-2.46,1.22-3.27,1.89-3.49a2.55,2.55,0,0,1,.67-.12c1-.09,2,0,3.44,2.41a.32.32,0,0,0,.31.13c.25-.09,1.21-.33,1.72-.44a.49.49,0,0,0,.28-.19,4,4,0,0,1,3.26-3.29,4.2,4.2,0,0,1,.83.06A3.68,3.68,0,0,1,52.8,18a.37.37,0,0,0,.12.22A6.75,6.75,0,0,1,56.57,23,6.53,6.53,0,0,1,55,28.65a5.06,5.06,0,0,1-3.39,1.9A3,3,0,0,1,48.68,29c-.61-1.38,0-2.48,1-3.77.21-.25.32-.49.24-.68-.27-.46-1.08-.39-1.53-.35a5.74,5.74,0,0,0-1.76.38.26.26,0,0,0-.2.34A41.52,41.52,0,0,1,48,30.11a11.54,11.54,0,0,1,.41,2.12c.22,2.46-1.28,3.28-1.88,3.49a3.18,3.18,0,0,1-.8.13c-2.11.19-3.31-2.35-3.94-3.57-.64-1.37-1.36-3.2-2.05-5.16-.17-.45-.82-.06-1.11,0a3.11,3.11,0,0,1-.8.13A2.89,2.89,0,0,1,35,25.8c-.29-.66-.86-2.4,1.47-4.11.33-.25.77-.52,1.2-.79A.27.27,0,0,0,37.76,20.6Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M91.72,26a8.71,8.71,0,0,1-7.59,6.05,8,8,0,0,1-4.28-.77,8.84,8.84,0,0,1-2.51-1.8,14.23,14.23,0,0,1-1.09-1.37.26.26,0,0,0-.46,0c-1.22,1.77-3.52,4.62-6.27,4.86a2.93,2.93,0,0,1-2.26-.85A4.38,4.38,0,0,1,66.08,29a14.65,14.65,0,0,1,.35-2.74,57.32,57.32,0,0,1,2.08-7.49,47.61,47.61,0,0,1,3.13-7.13c1.48-2.84,2.66-3.91,4.18-4a3.44,3.44,0,0,1,1.9.45c2.14,1.32,1.54,4.6-.92,10-.13.34.13.74.42.72a3.58,3.58,0,0,1,2.88.89c1.24,1.1,1.56,5,3.5,4.88,1.2-.11,1.62-1.25,1.93-2.19.41-1.31,1-2.86,2.9-3a3.26,3.26,0,0,1,2.74,1.16A4.29,4.29,0,0,1,92,22.64,8.66,8.66,0,0,1,91.72,26Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M116,16.53a3.34,3.34,0,0,1-2.73-1.1,3.28,3.28,0,0,1-.78-1.43.25.25,0,0,0-.25-.21c-.52,0-1-.07-1.61-.12a56.43,56.43,0,0,0-7,.12c-1.33.12-3.66.32-4.54-1.95-.52-1-.58-3.21,2-4.48a15.92,15.92,0,0,1,6-1.44,24.58,24.58,0,0,1,8.14.5,8.89,8.89,0,0,1,4.47,2.31,3.9,3.9,0,0,1,.73,3.79A5.56,5.56,0,0,1,116,16.53Zm-16.39,7a3.32,3.32,0,0,1,3-2.91c1.13-.1,2.14.57,3.14.84a18.29,18.29,0,0,0,5.82.17,20.43,20.43,0,0,0,4.63-.83c1.82-.42,3.64-.58,4.77,1.22,1,1.41.79,3-.59,4.42a11.75,11.75,0,0,1-4.12,2.12,20,20,0,0,1-4.66.9,16.91,16.91,0,0,1-8.25-1.07,8,8,0,0,1-2.64-1.89A4.13,4.13,0,0,1,99.6,23.48Z' transform='translate(0 .49)' />
            <path className={classes.cls4} d='M146.64,14.92a9.25,9.25,0,0,1-.39,1.9,3.71,3.71,0,0,1-3.26,3c-1.59.14-2.84-.67-3.74-2.38a15.36,15.36,0,0,1-.64-1.8,32.07,32.07,0,0,1-1.19-5,32.58,32.58,0,0,1-.67-5.06,7.35,7.35,0,0,1,0-1.89,4.58,4.58,0,0,1,4.53-4.19,4.55,4.55,0,0,1,5.12,3.34c.1.45.22,1.09.32,1.87a35.71,35.71,0,0,1,.22,5.1A43,43,0,0,1,146.64,14.92Zm.8,9.56a4,4,0,1,1-4.37-3.67A4,4,0,0,1,147.44,24.48Z' transform='translate(0 .49)' />
          </g>
          <g id='rule3-win'>
            <path className={classes.cls2} d='M35.48,55.18c-4.55,1-7.27-2-9.25-4.62-.65,3.08-2,7-6.54,8a7.6,7.6,0,0,1-5.44-.87c-2.31-1.38-3.7-3.77-4.72-5.52-1.41-2.37-3.11-5.66-4.29-8-1.73-3.6-3-6.39-3.79-8.59-.43-1.07-.73-2-1-2.74l-.14-.44,0-.1c-1.2-4.78,1.22-7.88,3.9-9.2a6.6,6.6,0,0,1,1.69-.55c3.53-.77,5.94.84,7.67,2.84a9.9,9.9,0,0,1,1.23-2.56,6.93,6.93,0,0,1,4.67-3.22,7.09,7.09,0,0,1,5.74,1,11.47,11.47,0,0,1,2.1,1.76c.76-2.5,2.32-5,5.81-5.74A6.6,6.6,0,0,1,35,16.51c2.88.09,6.37,1.91,7.22,6.72a21.7,21.7,0,0,1,.34,3.54c.15,2.31.18,5.36.1,9.28-.12,4.15-.3,7.06-.57,9.19l0,.48c-.25,2.07-.53,4.42-1.88,6.36A7.64,7.64,0,0,1,35.48,55.18Z' transform='translate(0 .49)' />
            <path className={classes.cls7} d='M28.07,47.55c-1-1.36-2.13-3.14-3.34-5a.28.28,0,0,0-.49.1c-.28,2.23-.67,4.35-.95,6-.59,3.18-1.22,6.15-4.3,6.82a4.32,4.32,0,0,1-3.12-.51c-1.46-.87-2.51-2.6-3.52-4.33-1.2-2-2.71-4.88-4.2-7.83s-2.77-6-3.65-8.28c-.47-1.17-.76-2.13-1-2.92-.91-3.62,1.25-5,2.21-5.48a3.68,3.68,0,0,1,.91-.28c3.28-.71,5,2.22,6.56,4.81.47.79,1.06,1.77,1.6,2.88a.31.31,0,0,0,.52-.11c.21-1.41.37-2.63.59-3.74a14.67,14.67,0,0,1,1.65-4.93,3.71,3.71,0,0,1,2.65-1.85,3.83,3.83,0,0,1,3.26.57,13.1,13.1,0,0,1,3.5,3.82c.67.92,1.4,1.94,2.13,3.14a.27.27,0,0,0,.48-.1c0-1.24.17-2.37.27-3.28.32-3,.73-6.4,4-7.11a3.8,3.8,0,0,1,1-.12c1,0,3.61.41,4.24,4.09a21,21,0,0,1,.28,3.08c.16,2.47.17,5.65.1,9s-.25,6.5-.55,8.86c-.24,2-.41,4-1.38,5.38A4.39,4.39,0,0,1,34.79,52C31.72,52.66,30,50.19,28.07,47.55Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M55.1,50.94c-2.85.61-6.62-.38-8.64-5-.21-.5-.4-1.06-.62-1.71-.14-.4-.29-.85-.46-1.33l-.05-.13-.1-.38c-.64-2.37-1.44-5.31-2.13-8.52s-1.19-6.21-1.58-8.63l-.07-.39v-.13c0-.51-.08-1-.13-1.41-.07-.68-.12-1.26-.14-1.73C41.1,16.44,44.12,14,47,13.36s6.73.37,8.67,5.07a33.09,33.09,0,0,1,1.12,3.18c.68,2.22,1.43,5.2,2.21,8.82s1.33,6.65,1.64,9a30.73,30.73,0,0,1,.29,3.3C61.07,47.82,57.88,50.33,55.1,50.94Z' transform='translate(0 .49)' />
            <path className={classes.cls7} d='M53.61,22.52c.72,2.34,1.46,5.36,2.16,8.6s1.28,6.3,1.59,8.73a28.93,28.93,0,0,1,.27,3c.13,3.75-2.21,4.68-3.23,4.89s-3.44.33-4.95-3.12c-.27-.66-.58-1.66-1-2.84-.64-2.36-1.46-5.36-2.16-8.61s-1.2-6.31-1.59-8.73c-.1-1.25-.24-2.28-.27-3,0-3.76,2.21-4.68,3.22-4.9s3.54-.34,5,3.13A26.22,26.22,0,0,1,53.61,22.52Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M90,43.56c-2.81.61-5,.15-14.2-6.75.13,1,.18,1.79.22,2.45l0,.45v.1c0,4.92-3,7.32-5.75,7.93s-6.63-.34-8.55-4.91c-.31-.71-.61-1.56-1-2.54l-.25-.68,0-.12c-.51-1.86-1.45-5.3-2.19-8.74S57,24.18,56.66,21.84l0-.13L56.57,21c-.08-1.06-.16-2-.15-2.75-.14-4.06,2.16-7.29,5.72-8.07,2.9-.62,5-.2,14.28,6.77a21.73,21.73,0,0,1-.23-3c-.1-5,2.89-7.43,5.72-8s6.54.38,8.55,5a23,23,0,0,1,1.1,3.25c.77,2.34,1.56,5.41,2.32,8.89s1.28,6.58,1.54,8.93a24.81,24.81,0,0,1,.34,3.08l0,.28C95.91,39.46,93.52,42.79,90,43.56Z' transform='translate(0 .49)' />
            <path className={classes.cls7} d='M73.15,30.67a13,13,0,0,0-1.54-1.19c-.16-.18-.43,0-.37.24.57,2.64.93,5.06,1.22,7a30.78,30.78,0,0,1,.29,3.12c0,3.64-2.24,4.52-3.18,4.72s-3.44.36-4.84-3c-.34-.78-.68-1.76-1.1-3-.61-2.24-1.46-5.36-2.15-8.56s-1.23-6.44-1.61-8.81c-.1-1.25-.2-2.29-.19-3-.09-2.18.84-4.34,3.15-4.84,1.66-.35,3-.64,16.18,9.69a15.17,15.17,0,0,1,1.53,1.2c.19.08.45-.06.41-.26-.57-2.63-1-5.17-1.23-7.07a20.63,20.63,0,0,1-.27-3c-.09-3.75,2.22-4.63,3.15-4.83s3.36-.35,4.87,3.1a21.83,21.83,0,0,1,1,2.84c.75,2.3,1.54,5.35,2.26,8.68s1.25,6.38,1.5,8.7a23.27,23.27,0,0,1,.33,3.11c.07,2.07-1,4.33-3.24,4.82C87.7,40.71,86.32,41,73.15,30.67Z' transform='translate(0 .49)' />
            <path className={classes.cls2} d='M106.5,42.91a8.43,8.43,0,0,1-8.85-12.79,9.91,9.91,0,0,1-2.07-2.31,12.89,12.89,0,0,1-1.51-2.71,58.52,58.52,0,0,1-2.42-6.42,67.42,67.42,0,0,1-1.73-6.57l0-.14c-.2-1.14-.3-2.09-.35-2.68V9.06A9.37,9.37,0,0,1,97.25-.23a9,9,0,0,1,10.59,5.28A12.59,12.59,0,0,1,108.76,8a53.83,53.83,0,0,1,1.61,13.71,14.12,14.12,0,0,1-.2,2.93,10.16,10.16,0,0,1-.94,3,8.42,8.42,0,0,1-2.73,15.3Z' transform='translate(0 .49)' />
            <path className={classes.cls7} d='M107.11,21.62A11.34,11.34,0,0,1,107,24c-.39,2.5-1.6,3.95-3.59,4.38s-3.77-.37-5.12-2.49a9.4,9.4,0,0,1-1.18-2.12,53.48,53.48,0,0,1-2.29-6.08c-.75-2.3-1.27-4.52-1.66-6.3-.16-.94-.25-1.77-.3-2.39A6.06,6.06,0,0,1,97.94,3a5.73,5.73,0,0,1,6.94,3.46,10,10,0,0,1,.71,2.3,51.41,51.41,0,0,1,1.52,12.9Zm2.59,12a5.12,5.12,0,1,1-6.07-4A5.14,5.14,0,0,1,109.7,33.61Z' transform='translate(0 .49)' />
          </g>
        </defs>
        <use xlinkHref="#rule3-black-cat"/>
        <use xlinkHref="#rule3-balloon"/>
        {voice}
        <use xlinkHref="#rule3-black-cat-piece" x='170' y='175'/>
        <use xlinkHref="#rule3-black-cat-piece" x='245' y='160'/>
        <use xlinkHref="#rule3-black-cat-piece" x='320' y='145'/>
        {(type === 'nyanko' || type === 'nyan' ) && (
          <use xlinkHref="#rule3-black-cat-piece" x='395' y='130'/>
        )}
        {type === 'nyanko' && (
          <use xlinkHref="#rule3-black-cat-piece" x='470' y='115'/>
        )}
        {type === 'nyanko' && (
          <use xlinkHref="#rule3-win" x='380' y='65'/>
        )}
      </svg>
    )
  }
}

export default withStyles(styles, { withTheme: true })(RuleNo3)

