import { WID } from "./Language";

export const POST_TYPES = {
  NEWS: 'news',
  EVENTS: 'events',
  GOODS: 'goods',
}

export const PICTURES = {
  TOP_IMAGE: '/photos/top_image.png',
  BASE: '/photos/base.png',
  AMAZON: '/photos/amazon.png',
}

export const puzzleLevelProps = [
  { lower_limit :  0, upper_limit :  15 },
  { lower_limit : 15, upper_limit :  25 },
  { lower_limit : 25, upper_limit :  35 },
  { lower_limit : 35, upper_limit :  45 },
  { lower_limit : 45, upper_limit :  55 },
  { lower_limit : 55, upper_limit :  65 },
  { lower_limit : 65, upper_limit :  75 },
  { lower_limit : 75, upper_limit :  85 },
  { lower_limit : 75, upper_limit :  95 },
  { lower_limit : 75, upper_limit : 100 }
]

export const LANGUAGE_SHORT_CODE = {
  JA: 'ja',
  EN: 'en',
  CN: 'cn',
  TW: 'tw',
  KO: 'ko',
}

export const languageProps = [
  { value: LANGUAGE_SHORT_CODE.JA, label: '日本語' },
  { value: LANGUAGE_SHORT_CODE.EN, label: 'English' },
  { value: LANGUAGE_SHORT_CODE.CN, label: '中文简体' },
  { value: LANGUAGE_SHORT_CODE.TW, label: '中文繁體' },
  { value: LANGUAGE_SHORT_CODE.KO, label: '한국어' },
]


export const PROFILE_SYNC_STATUS = {
  NONE: 'NONE',
  START: 'START',
  FINISH: 'FINISH',
}

export const ERROR_USER_MANAGER = {
  ALREADY_EXIST: 'ALREADY_EXIST',
  UNDEFINED_NAME: 'UNDEFINED_NAME',
  VERIFY_FAILED: 'VERIFY_FAILED',
  JWT_VERIFY_FAILED: 'JWT_VERIFY_FAILED',
}

export const errorUserManagerProps = [
  { value: ERROR_USER_MANAGER.ALREADY_EXIST, wid: '既に登録されています。'  }
]


export const menuItems = [
  { wid: WID.GLOBAL_NAVIGATION.HOME, url: '/' },
  { wid: WID.GLOBAL_NAVIGATION.RULE, url: '/rule' },
  { wid: WID.GLOBAL_NAVIGATION.PLAY, url: '/play' },
  { wid: WID.GLOBAL_NAVIGATION.TOPICS, url: '/topics' },
  { wid: WID.GLOBAL_NAVIGATION.GOODS, url: '/goods' },
]