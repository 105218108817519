const defLineProps = {
  six: [
    { target: [1,1,1,1,1,0],       point: [0,0,0,0,0,1] },
    { target: [1,1,1,1,0,1],       point: [0,0,0,0,1,0] },
    { target: [1,1,1,0,1,1],       point: [0,0,0,1,0,0] },
    { target: [1,1,0,1,1,1],       point: [0,0,1,0,0,0] },
    { target: [1,0,1,1,1,1],       point: [0,1,0,0,0,0] },
    { target: [0,1,1,1,1,1],       point: [1,0,0,0,0,0] },
  ],
  five: [
    { target: [1,1,1,1,0],         point: [0,0,0,0,1] },
    { target: [1,1,1,0,1],         point: [0,0,0,1,0] },
    { target: [1,1,0,1,1],         point: [0,0,1,0,0] },
    { target: [1,0,1,1,1],         point: [0,1,0,0,0] },
    { target: [0,1,1,1,1],         point: [1,0,0,0,0] }
  ],
  doubleFour: [
    { target: [1,1,1,0,0,0,1,1,1], point: [0,0,0,0,1,0,0,0,0] },
    { target: [1,1,0,0,1,0,1,1],   point: [0,0,0,1,0,0,0,0]   },
    { target: [1,1,0,1,0,0,1,1],   point: [0,0,0,0,1,0,0,0]   },
    { target: [1,0,0,1,1,0,1],     point: [0,0,1,0,0,0,0]     },
    { target: [1,0,1,0,1,0,1],     point: [0,0,0,1,0,0,0]     },
    { target: [1,0,1,1,0,0,1],     point: [0,0,0,0,1,0,0]     },
  ],
  four: [
    { target: [1,1,1,0,0],         point: [0,0,0,1,1] },
    { target: [1,1,0,1,0],         point: [0,0,1,0,1] },
    { target: [1,1,0,0,1],         point: [0,0,1,1,0] },
    { target: [1,0,1,1,0],         point: [0,1,0,0,1] },
    { target: [1,0,1,0,1],         point: [0,1,0,1,0] },
    { target: [1,0,0,1,1],         point: [0,1,1,0,0] },
    { target: [0,1,1,1,0],         point: [1,0,0,0,1] },
    { target: [0,1,1,0,1],         point: [1,0,0,1,0] },
    { target: [0,1,0,1,1],         point: [1,0,1,0,0] },
    { target: [0,0,1,1,1],         point: [1,1,0,0,0] },
  ],
  three: [
    { target: [0,1,1,0,0,0],       point: [0,0,0,1,1,0] },
    { target: [0,1,0,1,0,0],       point: [0,0,1,0,1,0] },
    { target: [0,1,0,0,1,0],       point: [0,0,1,1,0,0] },
    { target: [0,0,1,1,0,0],       point: [0,1,0,0,1,0] },
    { target: [0,0,1,0,1,0],       point: [0,1,0,1,0,0] },
    { target: [0,0,0,1,1,0],       point: [0,1,1,0,0,0] }
  ]
}

const defRotate = ['0', '45', '90', '315']
const defLineName = ['six', 'five', 'doubleFour', 'four', 'three']

class Engine {
  constructor() {
    this.init()
  }

  init() {
    var x, y
    this.boardWidth = 15
    this.root = true

    this.arrayBoard = new Array(this.boardWidth * this.boardWidth)
    for (y = 0; y < this.boardWidth; y++) {
      for (x = 0; x < this.boardWidth; x++) {
        this.arrayBoard[x + y * this.boardWidth] = {
          x, y, color: 0,
          '0': '', '45': '', '90': '', '315': ''
        }
      }
    }
    this.threeList = []
  }

  setMoves(moves, targetColor) {
    if (moves === undefined) {
      return
    }
    this.convertToArrayBoard(moves, targetColor)
  }

  convertToArrayBoard(moves, targetColor) {
    moves.forEach(move => {
      if (move.color === null) {
        return
      }
      if (targetColor === 'blackcat') {
        this.arrayBoard[move.x + move.y * this.boardWidth].color =
          move.color === 'blackcat' ? 1 : move.color === 'whitecat' ? 2 : 0
      }
      else if (targetColor === 'whitecat') {
        this.arrayBoard[move.x + move.y * this.boardWidth].color =
          move.color === 'blackcat' ? 2 : move.color === 'whitecat' ? 1 : 0
      }
    })
  }

  checkLine() {
    var arrayBoard
    defRotate.forEach(rotate => {
      arrayBoard = this.rotateArrayBoard(this.arrayBoard, rotate)
      defLineName.forEach(lineName => {
        defLineProps[lineName].forEach(lineProps => {
          arrayBoard.forEach(row => {
            this.checkLineForRow(rotate, lineName, lineProps, row)
          })
        })
      })
    })
  }

  checkLineForRow(rotate, lineName, lineProps, row) {
    var index, stonePos
    if (row.length < lineProps.target.length) {
      return
    }
    for (index = 0; index <= row.length - lineProps.target.length; index++) {
      stonePos = []
      if (this.compareLineAndRow(row, index, stonePos, lineProps, lineName)) {
        this.setLineName(row, lineProps, lineName, index, stonePos, rotate)
      }
    }
  }

  
  compareLineAndRow(row, index, stonePos, lineProps, lineName) {
    return row.slice(index, index + lineProps.target.length)
    .every((cell, rIndex) => {
      if (cell.color === 1 && cell.color === lineProps.target[rIndex]) {
        stonePos.push(cell)
      }
      return cell.color === lineProps.target[rIndex]
    })

  }

  setLineName(row, lineProps, lineName, index, stonePos, rotate) {
    var threeLine, sfIndex
    lineProps.point.forEach((cell, pIndex) => {
      if (cell !== 1) {
        return
      }
      if (lineName === 'three') {
        sfIndex = lineProps.point.findIndex((lineCell, idx) => { return lineCell === 1 && pIndex !== idx })
        threeLine = this.threeList.find(ln => {
          return ln.threePos === row[index + pIndex] &&
                 stonePos.every(stone => ln.stonePos.some(st => st === stone))
        })
        if (threeLine === undefined) {
          this.threeList.push({
            threePos: row[index + pIndex],
            stonePos,
            straightFourPos: [row[index + sfIndex]],
            rotate
          })
        }
        else {
          threeLine.straightFourPos.push(row[index + sfIndex])
        }
      }
      if (row[index + pIndex][rotate] === '') {
        row[index + pIndex][rotate] = lineName
      }
    })
  }

  rotateArrayBoard(arrayBoard, deg = '0') {
    var lines, x, y
    lines = []
    switch(deg) {
      case '0':
        for (y = 0; y < this.boardWidth; y++) {
          lines.push(arrayBoard.slice(y * this.boardWidth, (y + 1) * this.boardWidth))
        }
        break
      case '45':
        for (y = 0; y < this.boardWidth * 2; y++) {
          lines.push([])
          for (x = 0; x < y + 1 && x < this.boardWidth; x++) {
            if (x + (y - x) * this.boardWidth >= this.boardWidth * this.boardWidth) {
              continue
            }
            lines[y].push(this.arrayBoard[x + (y - x) * this.boardWidth])
          }
        }
        break
      case '90':
        for (x = 0; x < this.boardWidth; x++) {
          lines.push([])
          for (y = this.boardWidth - 1; y >= 0; y--) {
            lines[x].push(this.arrayBoard[x + y * this.boardWidth])
          }
        }
        break
      case '315':
        for (x = 0; x < this.boardWidth * 2; x++) {
          lines.push([])
          for (y = 0; y < x + 1 && y < this.boardWidth; y++) {
            if (this.boardWidth - 1 - x + y < 0) {
              continue
            }
            lines[x].push(this.arrayBoard[this.boardWidth - 1 - x + y + y * this.boardWidth])
          }
        }
        break
      default: 
        break
    }
    return lines
  }

  getRotateRow(arrayBoard, targetX, targetY, deg = '0') {
    var lines, x, y
    lines = []
    switch(deg) {
      case '0':
        lines = arrayBoard.slice(targetY * this.boardWidth, (targetY + 1) * this.boardWidth)
        break
      case '45':
        y = targetY + targetX
        for (x = 0; x < y + 1 && x < this.boardWidth; x++) {
          if (x + (y - x) * this.boardWidth >= this.boardWidth * this.boardWidth) {
            continue
          }
          lines.push(this.arrayBoard[x + (y - x) * this.boardWidth])
        }
        break
      case '90':
        for (y = this.boardWidth - 1; y >= 0; y--) {
          lines.push(this.arrayBoard[targetX + y * this.boardWidth])
        }
        break
      case '315':
        x = this.boardWidth - 1 - targetX  + targetY
        for (y = 0; y < x + 1 && y < this.boardWidth; y++) {
          if (this.boardWidth - 1 - x + y < 0) {
            continue
          }
          lines.push(this.arrayBoard[this.boardWidth - 1 - x + y + y * this.boardWidth])
        }
        break
      default: 
        break
    }
    return lines
  }

  isFiveLine(x, y) {
    var cell = this.arrayBoard[x + y * 15]
    return defRotate.some(rotate => cell[rotate] === 'six' || cell[rotate] === 'five')
  }

  getCalls(x, y) {
    var calls, cell
    calls = []
    cell = this.arrayBoard[x + y * 15]
    defRotate.forEach(rotate => {
      if (cell[rotate] === 'six' || cell[rotate] === 'five') {
        calls.push('nyanko')
      }
    })
    defRotate.forEach(rotate => {
      if (cell[rotate] === 'doubleFour') {
        calls.push('nyan')
        calls.push('nyan')
      }
      else if (cell[rotate] === 'four') {
        calls.push('nyan')
      }
    })
    defRotate.forEach(rotate => {
      if (cell[rotate] === 'three') {
        calls.push('nya')
      }
    })
    return calls
  }

  printArrayBoard(arrayBoard) {
    var num, strings
    strings = ''
    for (num = 0; num < this.boardWidth; num++) {
      strings += this.addStrings(arrayBoard, num)
      strings += `\n`
    }
    console.log(strings)
  }

  addStrings(arrayBoard, num) {
    var strings
    strings = ''
    arrayBoard
    .slice(num * this.boardWidth, (num + 1) * this.boardWidth)
    .forEach(cell => {
      strings += cell.color === 0 ? '+' : (cell.color === 1 ? '●' : '○')
    })
    return strings
  }

  printTwoDimArrayBoard(twoDimArrayBoard) {
    var strings
    strings = ''
    twoDimArrayBoard.forEach(row => {
      row.forEach(cell => {
        strings += cell.toString(10)
      })
      strings += `\n`
    })
    console.log(strings)
  }
}

export default Engine