import { connect } from 'react-redux'
import { NyankoPuzzleScoreCard } from '../../components'
import * as actions from '../../actions'

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  const { syncStatus } = state.user
  const { highScore, ranking } = state.puzzle.nyanko
  const { language } = state.settings
  return ({
    width,
    height,
    language,
    highScore,
    ranking,
    syncStatus,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    handleDidMount: () => {
      dispatch(actions.puzzle.nyanko.fetchRanking)
    },
    handleRefreshRanking: () => {
      dispatch(actions.puzzle.nyanko.fetchRanking)
    },
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NyankoPuzzleScoreCard)
