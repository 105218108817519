import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core"
import PostTypeTag from './PostTypeTag'
import moment from 'moment'
import { POST_TYPES } from '../../constants';

const styles = theme => ({
  buttonBase: {
    width: '100%',
  },
  card: {
    boxShadow: 'none',
    borderRadius: 0,
    height: 300,
    width: '100%',
  },
  cardMedia: {
    height: 200,
    width: '100%',
    objectFit: 'cover'
  },
  cardNonMedia: {
    position: 'relative',
    height: 200,
    width: '100%',
    backgroundColor: theme.palette.grey[200],
  },
  tagAndDate: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit,
  },
  postDate: {
    color: theme.palette.grey[500],
    fontSize: '0.7rem',
  },
  eventDate: {
    fontSize: '0.725em',
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
    fontStyle: 'normal',
    color: theme.palette.grey[800]
  },
  title: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
    fontStyle: 'normal',
  },
})

class TopicsCard extends React.Component {
  render() {
    const { classes, post } = this.props
    var visibleCardMedia = Boolean(
      post._embedded !== undefined &&
      post._embedded['wp:featuredmedia'] !== undefined &&
      post._embedded['wp:featuredmedia'].length > 0 &&
      post._embedded['wp:featuredmedia'][0].source_url !== undefined
    )

    return (
      <Card className={classes.card}>
        {visibleCardMedia ? (
          <CardMedia
            className={classes.cardMedia}
            image={post._embedded['wp:featuredmedia'][0].source_url}
            component='img'
          />
        ) : (
          <div className={classes.cardNonMedia}>
          </div>
        )}
        <CardContent className={classes.cardContent}>
          <div className={classes.tagAndDate}>
            <PostTypeTag type={post.type}/>
            <div className={classes.postDate}>{moment(post.date).format('YYYY.MM.DD')}</div>
          </div>
          {post.type === POST_TYPES.EVENTS && post.meta.start_date && (
            <Typography className={classes.eventDate}>{moment(post.meta.start_date, 'YYYYMMDD').format('YYYY/MM/DD')}</Typography>
          )}
          <Typography className={classes.title}>{post.title.rendered}</Typography>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles, { withTheme: true })(TopicsCard)