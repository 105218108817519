export const types = {
  router: {
    LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  },

  window: {
    RESIZE: 'window/RESIZE',
  },

  user: {
    CHANGED_SYNC_PROFILE_STATUS: 'user/CHANGED_SYNC_PROFILE_STATUS',
    REQUEST_PROFILE: 'user/REQUEST_PROFILE',
    RECEIVE_PROFILE: 'user/RECEIVE_PROFILE',
  },

  puzzle: {
    nyanko: {
      UPDATE_HIGH_SCORE: 'puzzle/nyanko/UPDATE_HIGH_SCORE',
      RECEIVE_PUZZLES: 'puzzle/nyanko/RECEIVE_PUZZLES',
      RECEIVE_RANKING: 'puzzle/nyanko/RECEIVE_RANKING'
    }
  },

  settings: {
    CHANGED_LANGUAGE: 'settings/CHANGED_LANGUAGE',
    CHANGED_SYNC_PROFILE_STATUS: 'settings/CHANGED_SYNC_PROFILE_STATUS',
    REQUEST_PROFILE: 'settings/REQUEST_PROFILE',
    RECEIVE_PROFILE: 'settings/RECEIVE_PROFILE',
  },

  wordpress: {
    RECEIVE_CUSTOM_POSTS: 'wordpress/RECEIVE_CUSTOM_POSTS',
    RECEIVE_NEWS: 'wordpress/RECEIVE_NEWS',
    RECEIVE_EVENTS: 'wordpress/RECEIVE_EVENTS',
    RECEIVE_MEDIA: 'wordpress/RECEIVE_MEDIA',
  },

  board: {
    CLICK: 'board/CLICK',
    RIGHT_CLICK: 'board/RIGHT_CLICK',
  },
}