import React from 'react'
import { Route, Switch } from 'react-router'
import { TopicsTopPage, TopicsPage } from '../../containers'
import PageHeader from '../../components/Common/PageHeader';
import { Word } from '../../containers';
import { WID } from '../../constants/Language';
import { LANGUAGE_SHORT_CODE } from '../../constants';

class TopicsRouting extends React.Component {
  render() {
    var regstr = Object.keys(LANGUAGE_SHORT_CODE).map(key => LANGUAGE_SHORT_CODE[key]).join('|')
    return (
      <PageHeader title={<Word wid={WID.TOPICS_PAGE.HEADER}/>}>
        <Switch>
          <Route exact path='/topics' component={TopicsTopPage} />
          <Route exact path={`/:language(${regstr})/topics`} component={TopicsTopPage} />
          <Route path={`/:language(${regstr})/topics/:type/:slug`} component={TopicsPage} />
          <Route path='/topics/:type/:slug' component={TopicsPage} />
        </Switch>
      </PageHeader>
    )
  }
}

export default (TopicsRouting)
