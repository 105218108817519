import React from 'react'
import { Route, Switch } from 'react-router'
import { GoodsTopPage, GoodsPage } from '../../containers'
import PageHeader from '../../components/Common/PageHeader';
import { Word } from '../../containers';
import { WID } from '../../constants/Language';
import { LANGUAGE_SHORT_CODE } from '../../constants';

class GoodsRouting extends React.Component {
  render() {
    var regstr = Object.keys(LANGUAGE_SHORT_CODE).map(key => LANGUAGE_SHORT_CODE[key]).join('|')
    return (
      <PageHeader title={<Word wid={WID.GOODS_PAGE.HEADER}/>}>
        <Switch>
          <Route exact path='/goods' component={GoodsTopPage} />
          <Route exact path={`/:language(${regstr})/goods`} component={GoodsTopPage} />
          <Route path={`/:language(${regstr})/goods/:slug`} component={GoodsPage} />
          <Route path='/goods/:slug' component={GoodsPage} />
        </Switch>
      </PageHeader>
    )
  }
}

export default (GoodsRouting)
