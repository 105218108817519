import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Board, Word } from '../../containers'
import { Typography } from '@material-ui/core'
import { PlayNavigation } from './Common'
import Engine from '../../lib/Engine';
import { WID } from '../../constants/Language';
import { makePath } from '../../lib';

const stylesForControlButtons = theme => ({
  root: {
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: theme.spacing.unit * 5,
    margin: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 5px 0px 0px #00000022`,
    userSelect: 'none',
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow: 'none',
      transform: 'translate(0px,1px)',
    },
  },
  disabledButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: theme.spacing.unit * 5,
    margin: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    transition: 'all 0.5s',
    transform: 'translate(0px,1px)',
    backgroundColor: theme.palette.grey[200],
    userSelect: 'none',
  },
  buttonLabel: {
    color: theme.palette.primary.main,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1em',
  }
})

class ControlButtons extends React.Component {
  handleClickBack = () => {
    const { moves, onClickBack } = this.props
    if (moves.length === 0) {
      return
    }
    if (onClickBack !== undefined) {
      onClickBack()
    }
  }

  handleClickNext = () => {
    const { moves, history, onClickNext } = this.props
    if (moves.length >= history.length) {
      return
    }
    if (onClickNext !== undefined) {
      onClickNext()
    }
  }

  render() {
    const { classes, moves, history } = this.props
    var disabledBackButton = Boolean(moves.length === 0)
    var disalbedNextButton = Boolean(moves.length >= history.length)
    return (
      <div className={classes.root}>
        <div className={classes.buttonBox}>
          <div
            className={disabledBackButton ? classes.disabledButton : classes.button}
            onClick={this.handleClickBack}
          >
            <Typography className={classes.buttonLabel}><Word wid={WID.PLAY_PAGE.FREE_BOARD_PAGE.BACK}/></Typography>
          </div>
          <div
            className={disalbedNextButton ? classes.disabledButton : classes.button}
            onClick={this.handleClickNext}
          >  
            <Typography className={classes.buttonLabel}><Word wid={WID.PLAY_PAGE.FREE_BOARD_PAGE.NEXT}/></Typography>
          </div>
        </div>
      </div>
    )
  }
}

ControlButtons = withStyles(stylesForControlButtons, { withTheme: true })(ControlButtons)

const styles = theme => ({
})

class FreeBoardPage extends React.Component {
  state = {
    moves: [],
    history: [],
    calls: undefined,
  }

  handleClickBoard = (x, y) => {
    var color, engine, calls
    const { moves, history } = this.state
    var newMoves, newHistory
    if (moves.some(move => move.x === x && move.y === y)) {
      return
    }
    color = moves.length % 2 === 0 ? 'blackcat' : 'whitecat'

    engine = new Engine()
    engine.setMoves(moves, color)
    engine.checkLine()
    calls = engine.getCalls(x, y)

    newMoves = moves.concat({ x, y, color })
    newHistory = moves.concat({ x, y, color })
    if (
      newMoves.length <= history.length &&
      newMoves.every((move, index) => 
        Object.keys(move).every(key =>
          key in history[index] &&
          move[key] === history[index][key]
        )
      )
    ) {
      this.setState({ moves: newMoves, calls })
    }
    else {
      this.setState({ moves: newMoves, history: newHistory, calls })
    }
  }

  handleRightClickBoard = () => {
    const { moves } = this.state
    this.setState({ moves: moves.slice(0, moves.length - 1), calls: undefined })
  }

  handleClickBack = (event) => {
    const { moves } = this.state
    this.setState({ moves: moves.slice(0, moves.length - 1), calls: undefined })
  }

  handleClickNext = (event) => {
    var engine, calls
    const { moves, history } = this.state
    engine = new Engine()
    engine.setMoves(moves, moves.length % 2 === 0 ? 'blackcat' : 'whitecat')
    engine.checkLine()
    calls = engine.getCalls(history[moves.length].x, history[moves.length].y)
    this.setState({ moves: moves.concat(history[moves.length]), calls })
  }

  render() {
    const { moves, history, calls } = this.state
    const { language } = this.props

    return (
      <React.Fragment>
        <PlayNavigation title={<Word wid={WID.PLAY_PAGE.FREE_BOARD_PAGE.TITLE}/>} back={makePath('/play', language)}/>
        <Board
          moves={moves}
          calls={calls}
          onClick={this.handleClickBoard}
          onRightClick={this.handleRightClickBoard}
        />
        <ControlButtons
          moves={moves}
          history={history}
          onClickBack={this.handleClickBack}
          onClickNext={this.handleClickNext}
        />
      </React.Fragment>
		)
  }
}

export default withStyles(styles, { withTheme: true })(FreeBoardPage)
