import { types } from '../constants/ActionTypes'
import fetch from 'isomorphic-fetch'

const URL_RENJU_PORTAL = process.env.NODE_ENV !== 'production' ? 'http://renjuportal.com:3000' : 'https://v1.renjuportal.com'

export const window = {
  resize: (width, height) => ({
    type: types.window.RESIZE,
    width,
    height
  }),
}

export const user = {
  changedSyncProfileStatus: (status) => ({
    type: types.user.CHANGED_SYNC_PROFILE_STATUS,
    status,
  }),
  fetchRegister: (name) => (dispatch, getState) => {
    return fetch(`${URL_RENJU_PORTAL}/nyankonarabe/user/register`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name })
    })
    .then(response => response.json())
    .then(json => {
      return Promise.resolve(json)
    })
    .catch(error => {
      console.dir(error)
      return Promise.reject(error)
    })
  },
  fetchProfile: (token) => (dispatch, getState) => {
    dispatch(user.requestProfile(token))
    return fetch(`${URL_RENJU_PORTAL}/nyankonarabe/user/profile`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token })
    })
    .then(response => response.json())
    .then(json => {
      dispatch(user.receiveProfile(json))
      return Promise.resolve(json)
    })
    .catch(error => {
      console.dir(error)
      return Promise.reject(error)
    })
  },
  requestProfile: (token) => ({
    type: types.user.REQUEST_PROFILE,
    token,
  }),
  receiveProfile: (response) => ({
    type: types.user.RECEIVE_PROFILE,
    response
  })
}

export const puzzle = {
  nyanko: {
    updateHighScore: (score) => ({
      type: types.puzzle.nyanko.UPDATE_HIGH_SCORE,
      score,
    }),
    fetchRanking: (dispatch, getState) => {
      return fetch(`${URL_RENJU_PORTAL}/nyankonarabe/puzzle/nyanko/ranking`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify()
      })
      .then(response => response.json())
      .then(json => {
        dispatch(puzzle.nyanko.receiveRanking(json))
      })      
    },
    fetchSelect: (level, color, lower_limit, upper_limit) => (dispatch, getState) => {
      return fetch(`${URL_RENJU_PORTAL}/nyankonarabe/puzzle/nyanko/select`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ next_color: color, lower_limit, upper_limit })
      })
      .then(response => response.json())
      .then(json => {
        dispatch(puzzle.nyanko.receivePuzzles(json.puzzles, level, color))
      })
    },
    fetchUpdateHighScore: (token, score) => (dispatch, getState) => {
      return fetch(`${URL_RENJU_PORTAL}/nyankonarabe/puzzle/nyanko/update_high_score`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, score })
      })
      .then(response => response.json())
      .then(json => {
        return Promise.resolve(json)
      })
      .catch(error => {
        console.dir(error)
        return Promise.reject(error)
      })
    },
    receivePuzzles: (puzzles, level, color) => ({
      type: types.puzzle.nyanko.RECEIVE_PUZZLES,
      puzzles,
      level,
      color,
    }),
    receiveRanking: (response) => ({
      type: types.puzzle.nyanko.RECEIVE_RANKING,
      response,
    })
  }
}


export const settings = {
  changedLanguage: (value) => ({
    type: types.settings.CHANGED_LANGUAGE,
    language: value,
  }),
}

export const wordpress = {
  fetchCustomPosts: (postType, param = '') => (dispatch, getState) => {
    return fetch(`https://db.nyankonarabe.com/wp-json/wp/v2/${postType}/${param}`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(json => {
      dispatch(wordpress.receiveCustomPosts(json))
      return Promise.resolve(json)
    })
    .catch(error => {
      console.dir(error)
      return Promise.reject(error)
    })
  },
  fetchMedia: (url) => (dispatch, getState) => {
    return fetch(url, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(json => {
      dispatch(wordpress.receiveMedia(json))
      return Promise.resolve(json)
    })
    .catch(error => {
      console.dir(error)
      return Promise.reject(error)
    })
  },
  receiveCustomPosts: (response) => ({
    type: types.wordpress.RECEIVE_CUSTOM_POSTS,
    response,
  }),
  receiveMedia: (response) => ({
    type: types.wordpress.RECEIVE_MEDIA,
    response,
  }),
}

export const cpu = {
  fetchNextMove: (mode, moves) => (dispatch, getState) => {
    let formData = new FormData()
    formData.append('action', 'next_move_for_react')
    formData.append('mode', mode)
    formData.append('gamedata', moves)
    return fetch(`https://db.nyankonarabe.com/wp-admin/admin-ajax.php`, {
      method: 'POST',
      body: formData
    })
    .then(response => {
      return response.json()
    })
    .then(json => {
      return Promise.resolve(json)
    })
    .catch(error => {
      console.dir(error)
      return Promise.reject(error)
    })
  },
}

export const board = {
  click: (x, y) => ({
    type: types.board.CLICK,
    x,
    y,
  }),
  rightClick: () => ({
    type: types.board.RIGHT_CLICK,
  })
}
