import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, ButtonBase } from '@material-ui/core'
import { TopicsCard } from '../Common'
import { Link } from 'react-router-dom'
import { makePath } from '../../lib';

const styles = theme => ({
  buttonBase: {
    width: '100%',
  }
})

class TopicsTopPage extends React.Component {
  componentDidMount() {
    if (this.props.mount !== undefined) {
      this.props.mount()
    }
  }
  
  render() {
    const { classes, posts, language } = this.props

    return posts !== undefined ? (
      <Grid container spacing={16}>
        {posts.map(post => {
          return (
            <Grid item key={post.id} xs={12} sm={6} md={4} lg={3}>
              <ButtonBase component={Link} to={makePath(`/topics/${post.type}/${post.slug}`, language)} className={classes.buttonBase}>
                <TopicsCard post={post}/>
              </ButtonBase>
            </Grid>
          )
        })}
      </Grid>
    ) : (<div></div>)
  }
}

export default withStyles(styles, { withTheme: true })(TopicsTopPage)
