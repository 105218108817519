import { connect } from 'react-redux'
import { OverviewSection } from '../../components'

const mapStateToProps = (state, ownProps) => {
  const { width } = state.window
  const { language } = state.settings
  return ({
    width,
    language,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverviewSection)
