import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Fade } from '@material-ui/core'
import { ExpandMoreRounded as ExpandMoreRoundedIcon } from '@material-ui/icons'
import { OverviewSection, TopicsSection, GoodsSection } from '../../containers'
import { PICTURES } from '../../constants'

const stylesForTopImageSection = theme => ({
  scrollDownBox: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    left: 0,
    width: '100%',
    color: theme.palette.common.white,
    textAlign: 'center',
  }
})

class TopImageSection extends React.Component {
  state = {
    loaded: false,
  }

  handleLoadedImage = (event) => {
    this.setState({ loaded: true })
  }

  render() {
    const { classes, theme } = this.props
    var size, width, height
    ({ width, height } = this.props)
    const { loaded } = this.state
    if (height < 600) {
      height = 600
    }
    size = width !== undefined && height !== undefined ?
      parseInt((width > height ? height : width) * 0.9, 10) : undefined
    const styles = {
      section: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: height,
        backgroundColor: theme.palette.primary.main
      },
      image: {
        display: 'block',
        maxWidth: 1200,
        maxHeight: 1200,
        width: size,
        height: size,
        margin: `auto`,
      },
    }
    return (
      <div style={styles.section}>
        <Fade in={loaded}>
          <React.Fragment>
            <img
              src={PICTURES.TOP_IMAGE}
              onLoad={this.handleLoadedImage}
              alt='img'
              style={styles.image}
            />
            <div className={classes.scrollDownBox}>
              <ExpandMoreRoundedIcon color='inherit'/>
            </div>
          </React.Fragment>
        </Fade>
      </div>
    )
  }
}

TopImageSection = withStyles(stylesForTopImageSection, { withTheme: true })(TopImageSection)

const styles = theme => ({
})

class Home extends React.Component {
  render() {
    const { width, height } = this.props
    
    return(
      <div>
        <TopImageSection width={width} height={height}/>
        <OverviewSection/>
        <TopicsSection/>
        <GoodsSection/>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Home)
