import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Divider } from '@material-ui/core'
import { CalendarToday as CalendarTodayIcon, Place as PlaceIcon } from '@material-ui/icons'
import './TopicsPage.css'
import { PostTypeTag } from '../Common'
import moment from 'moment'
import { POST_TYPES } from '../../constants'
import PHP from '../../lib/php'
import GoogleMapReact from 'google-map-react'

const stylesForPostInfoBox = theme => ({
})

const PostInfoBox = withStyles(stylesForPostInfoBox, { withTheme: true })((props) => {
  const { post } = props

  return post.type === POST_TYPES.EVENTS ? (
    <EventPostInfoBox post={post}/>
  ) : (<div></div>)
})

const stylesForEventPostInfoBox = theme => ({
  eventPostInfoBox: {
  },
  itemBox: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[700],
    margin: `${theme.spacing.unit / 2}px 0`,
  },
  icon: {
    fontSize: '0.875em',
    marginRight: theme.spacing.unit,
  },
  googleMap: {
    height: 200,
    margin: theme.spacing.unit * 2,
  }
})

const EventPostInfoBox = withStyles(stylesForEventPostInfoBox, { withTheme: true })((props) => {
  const { classes, post } = props
  var strDatetime, strAddress, place
  if (post.meta.start_date !== undefined && post.meta.start_date[0] !== "") {
    strDatetime = moment(post.meta.start_date[0], 'YYYYMMDD').format('YYYY年M月D日')
    if (post.meta.start_time !== undefined && post.meta.start_time[0] !== "") {
      strDatetime += moment(post.meta.start_time[0], 'H:i:s').format(' HH:mm')
    }
    if (post.meta.end_date !== undefined && post.meta.end_date[0] !== "") {
      strDatetime += ' -'
      if (post.meta.start_date[0] !== post.meta.end_date[0]) {
        strDatetime += moment(post.meta.end_date, 'YYYYMMDD').format(' M月D日')
      }
      if (post.meta.end_time !== undefined && post.meta.end_time[0] !== "") {
        strDatetime += moment(post.meta.end_time[0], 'H:i:s').format(' HH:mm')
      }
    }
  }
  if (
    post !== undefined &&
    post.meta !== undefined &&
    post.meta.place !== undefined &&
    post.meta.place[0] !== ""
  ) {
    place = PHP.parse(post.meta.place[0])
    strAddress = place.address
  }

  var renderMarkers = (map, maps) => {
    new maps.Marker({
      position: {
        lat: parseFloat(place.lat),
        lng: parseFloat(place.lng),
      },
      map,
      title: place.address
    });
  }

  return (
    <div className={classes.eventPostInfoBox}>
      {strDatetime !== undefined && (
        <div className={classes.itemBox}>
          <Typography color='inherit'><CalendarTodayIcon color='inherit' className={classes.icon}/>{strDatetime}</Typography>
        </div>
      )}
      {strAddress !== undefined && (
        <React.Fragment>
          <div className={classes.itemBox}>
            <Typography color='inherit'><PlaceIcon color='inherit' className={classes.icon}/>{strAddress}</Typography>
          </div>
          <div className={classes.googleMap}>
            <GoogleMapReact
              key={place.address}
              bootstrapURLKeys={{ key: 'AIzaSyCKSiv9BUg4wbfCUdhi7M4fv8sYkyuv77c' }}
              defaultCenter={{
                lat: parseFloat(place.lat),
                lng: parseFloat(place.lng),
              }}
              onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals={true}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
})

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 10
  },
  tagAndDate: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 2,
  },
  date: {
    color: theme.palette.grey[500],
    fontSize: '0.7rem',
  },
  title: {
    fontWeight: 1000,
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  thumbnail: {
    width: '100%',
  },
  content: {
    width: '100%',
    overflow: 'hidden',
  },
  contentSpan: {
    color: theme.palette.grey[700],
    lineHeight: 2,
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
  },
})

class TopicsPage extends React.Component {
  componentDidMount() {
    this.props.mount()
  }
  
  render() {
    const { classes, post } = this.props

    var visibleThumbnail = Boolean(
      post !== undefined &&
      post._embedded !== undefined &&
      post._embedded['wp:featuredmedia'] !== undefined &&
      post._embedded['wp:featuredmedia'].length > 0 &&
      post._embedded['wp:featuredmedia'][0].source_url !== undefined
    )
    
    return post !== undefined ? (
      <div>
        <div>
          <div className={classes.tagAndDate}>
            <PostTypeTag type={post.type}/>
            <div className={classes.date}>{moment(post.date).format('YYYY.MM.DD')}</div>
          </div>
          <Typography variant='h6'>
            <span className={classes.title} dangerouslySetInnerHTML={{__html: post.title.rendered }}></span>
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <PostInfoBox post={post}/>
        {visibleThumbnail && (
          <img src={post._embedded['wp:featuredmedia'][0].source_url} alt='img' className={classes.thumbnail}/>
        )}
        <div className={classes.content}>
          <span dangerouslySetInnerHTML={{__html: post.content.rendered }} className={classes.contentSpan}></span>
        </div>
      </div>
    ) : (<div></div>)
  }
}

export default withStyles(styles, { withTheme: true })(TopicsPage)
