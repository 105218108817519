import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import window from './Window'
import wordpress from './WordPress'
import settings from './Settings'
import user from './User'
import puzzle from './Puzzle'

const reducers = combineReducers({
  user,
  window,
  wordpress,
  settings,
  puzzle,
  routing: routerReducer,
})

export default reducers