import { types } from '../constants/ActionTypes'
import { PROFILE_SYNC_STATUS } from '../constants';

const initialState = {
  name: '',
  syncStatus: PROFILE_SYNC_STATUS.NONE,
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.user.CHANGED_SYNC_PROFILE_STATUS:
      return {
        ...state,
        syncStatus: action.status
      }
    case types.user.REQUEST_PROFILE:
      return {
        ...state,
        syncStatus: PROFILE_SYNC_STATUS.START,
      }
    case types.user.RECEIVE_PROFILE:
      if (action.response.success !== true) {
        return { ...state, syncStatus: PROFILE_SYNC_STATUS.FINISH }
      }
      return {
        ...state,
        name: action.response.user.name,
        syncStatus: PROFILE_SYNC_STATUS.FINISH,
      }
    default:
      return state
  }
}

export default user