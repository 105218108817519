import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 4}px 0`,
    backgroundColor: theme.palette.grey[100],
  },
  copyrightBox: {
    textAlign: 'center',
  }
})

class Footer extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div></div>
        <div className={classes.copyrightBox}>
          <Typography>© 2019 fukufuku LLC</Typography>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Footer)
