import React from 'react'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { Drawer, List, ListItem, ListItemText, Typography, ButtonBase, IconButton, Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { MenuRounded as MenuIcon, Language as LanguageIcon } from '@material-ui/icons'
import { languageProps, LANGUAGE_SHORT_CODE, menuItems } from '../../constants'
import { Word } from '../../containers'
import { multipleWordsProps } from '../../constants/Language';
import { makePath, getPageCategory } from '../../lib'

const stylesMenuItemButtons = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  menuItem: {
    paddingRight: theme.spacing.unit * 1.5,
    paddingLeft: theme.spacing.unit * 1.5,
    boxSizing: 'border-box',
    height: '100%',
    transition: '.3s',
    borderBottom: `2px solid ${theme.palette.common.white}`,
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover .label': {
      color: theme.palette.primary.main,
    }
  },
  selected: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    '& .label': {
      color: theme.palette.primary.main,
    }
  },
  iconBox: {
    marginRight: theme.spacing.unit,
    color: theme.palette.common.black,
  },
  label: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    transition: '.3s',
  }
})

const MenuItemButtons = withStyles(stylesMenuItemButtons, { withTheme: true })((props) => {
  const { classes, pathname, language } = props
  var category = getPageCategory(pathname, language)
  return (
    <div className={classes.root}>
      {menuItems.map(item => {
        return (
          <ButtonBase
            className={`${classes.menuItem} ${`/${category}` === item.url ? classes.selected : ''}`}
            component={Link} to={makePath(item.url, language)} key={item.url}
          >
            <Typography className={`${classes.label} label`}><Word wid={item.wid}/></Typography>
          </ButtonBase>
        )
      }
      )}
    </div>
  )
})

const stylesForLanguageDialog = theme => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  languageIcon: {
    marginRight: theme.spacing.unit * 2,
  },
  listItemText: {
    textAlign: 'center',
    padding: 0,
  },
  buttonLabel: {
    color: theme.palette.common.white,
  }
})

const LanguageDialog = withRouter(withStyles(stylesForLanguageDialog, { withTheme: true })(({ classes, open, onClose, history, location }) => {

  function handleClose() {
    onClose()
  }

  const handleListItemClick = (value) => {
    history.push(makePath(location.pathname, value))
    onClose(value)
  }

  var completeRate = {}
  Object.keys(LANGUAGE_SHORT_CODE).forEach(key => {
    let complete = multipleWordsProps.filter(props => props[LANGUAGE_SHORT_CODE[key]] !== undefined && props[LANGUAGE_SHORT_CODE[key]] !== '' ).length
    let all = multipleWordsProps.length
    completeRate[[LANGUAGE_SHORT_CODE[key]]] = Math.round((complete / all) * 100)
  })

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <LanguageIcon className={classes.languageIcon}/>
          <Typography variant='h6'>Language</Typography>
        </div>
      </DialogTitle>
      <List>
        {languageProps.map(props => (
          <ListItem button onClick={() => handleListItemClick(props.value)} key={props.value}>
            <ListItemText primary={`${props.label} (${completeRate[props.value]}%)`} className={classes.listItemText}/>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button variant='contained' color='primary' fullWidth onClick={handleClose}>
          <Typography className={classes.buttonLabel}>Cancel</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}))

const LogoIcon = (props) => {
  return (
    <svg viewBox='0 0 100 100' {...props}>
      <circle cx='50' cy='50' r='50' fill='#8fc31f' />
      <path d='M73.76,44.53A21.42,21.42,0,0,0,75,36.75c0-3.85-.34-9-2.89-8.49-4.12.79-8.4,2.44-11.28,4.86A27.52,27.52,0,0,0,39,33.19c-2.88-2.46-7.21-4.14-11.37-4.93-2.55-.49-2.9,4.64-2.9,8.49a20.87,20.87,0,0,0,1.4,8A27.42,27.42,0,0,0,22.5,58.38c0,15.19,12.31,23.55,27.5,23.55s27.5-8.36,27.5-23.55A27.34,27.34,0,0,0,73.76,44.53Z' fill='#fff' />
    </svg>
  )
}


const styles = theme => ({
  root: {
  },
  navigation: {
    position: 'fixed',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: `calc(100%)`,
    height: theme.spacing.unit * 7,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 4px 4px #0001',
    transition: '.5s',
  },
  hide: {
    transform: 'translateY(-100%)',
  },
  loginButton: {
    width: '100px',
    borderRadius: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  },
  logoIcon: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  menuItemsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1200,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.whtie,
    margin: '0 auto',
  },
  menuItemLeftBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  iconButton: {
    color: theme.palette.primary.main,
    width: theme.spacing.unit * 7,
    height: theme.spacing.unit * 7,
  },
  languageIcon: {
    marginRight: theme.spacing.unit,
  },
  listItem: {
  },
  listItemText: {
    textAlign: 'center',
  },
  listItemTextPrimary: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
  }
})

class GlobalNavigation extends React.Component {
  state = {
    open: false,
    visible: true,
    languageDialog: {
      open: false,
    }
  }

  scrollY = -1

  componentDidMount() {
    const { handleDidMount } = this.props
    if (handleDidMount) {
      handleDidMount()
    }
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentWillReceiveProps(nextProps) {
  }

  handleScroll = () => {
    const { visible } = this.state

    if (this.scrollY !== -1 && window.scrollY > this.scrollY && visible === true) {
      this.setState({ visible: false })
    }
    else if (this.scrollY !== -1 && window.scrollY < this.scrollY && visible === false) {
      this.setState({ visible: true})
    }
    
    this.scrollY = window.scrollY
  }

  handleToggleDrawer = (open) => () => {
    this.setState({ open })
  }

  languageDialog = {
    handleClickOpen: () => {
      this.setState({ languageDialog: { open: true } })
    },
    handleClickClose: (value) => {
      const { handleChangedLanguage } = this.props
      if (value) {
        handleChangedLanguage(value)
      }
      this.setState({ languageDialog: { open: false }})
    }
  }

  render() {
    const { classes, pathname, width, language } = this.props
    const { open, visible, languageDialog } = this.state

    const fullList = (
      <div className={classes.fullList}>
        <List className={classes.listItem}>
          {menuItems.map(item => 
            <ListItem button key={item.wid} component={Link} to={makePath(item.url, language)}>
              <ListItemText primary={<Typography className={classes.listItemTextPrimary}><Word wid={item.wid}/></Typography>} className={classes.listItemText}/>
            </ListItem>
          )}
        </List>
      </div>
    )

    return(
      <React.Fragment>
        <div className={classes.root}>
          <div className={`${classes.navigation} ${visible ? '' : classes.hide}`}>
            {width > 650 ? (
              <div className={classes.menuItemsBox}>
                <div className={classes.menuItemLeftBox}>
                  <LogoIcon width='32' height='32' className={classes.logoIcon}/>
                  <MenuItemButtons pathname={pathname} language={language}/>
                </div>
                <div>
                  <IconButton className={classes.iconButton} onClick={this.languageDialog.handleClickOpen}>
                    <LanguageIcon/>
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className={classes.menuItemsBox}>
                <div className={classes.menuItemLeftBox}>
                  <LogoIcon width='32' height='32' className={classes.logoIcon}/>
                </div>
                <div>
                  <IconButton className={classes.iconButton} onClick={this.languageDialog.handleClickOpen}>
                    <LanguageIcon/>
                  </IconButton>
                  <IconButton className={classes.iconButton} onClick={this.handleToggleDrawer(true)}>
                    <MenuIcon/>
                  </IconButton>
                </div>
              </div>
            )}
          </div>
          <Drawer anchor="top" open={open} onClose={this.handleToggleDrawer(false)}>
            <div
              tabIndex={0}
              role="button"
              onClick={this.handleToggleDrawer(false)}
              onKeyDown={this.handleToggleDrawer(false)}
            >
              {fullList}
            </div>
          </Drawer>
        </div>
        {this.props.children}
        <LanguageDialog open={languageDialog.open} onClose={this.languageDialog.handleClickClose}/>
      </React.Fragment>
    )
  }
}

export default withStyles(styles, { withTheme: true })(GlobalNavigation)
