import { connect } from 'react-redux'
import { VSPage } from '../../components'
import * as actions from '../../actions'

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  const { language } = state.settings
  return ({
    width,
    height,
    language,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    handleMove: (player, moves, callback) => {
      let strMove = ''
      moves.forEach((move, index) => {
        strMove += (move.x + 1).toString(16).toUpperCase() + (15 - move.y).toString(16).toUpperCase()
        if (index !== moves.length - 1) {
          strMove += ','
        }
      })
      dispatch(actions.cpu.fetchNextMove(player, strMove))
      .then(response => {
        if (callback !== undefined) {
          callback({
            x: parseInt(response[0], 17) - 1,
            y: parseInt(response[1], 17) - 2,
            color: moves.length % 2 === 0 ? 'blackcat' : 'whitecat' 
          })
        }
      })
      .catch(error => {
        console.dir(error)
      })
    }
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VSPage)
