import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { PlayNavigation, RadioButtonGroup, Cat } from './Common'
import { Typography, Grid } from '@material-ui/core';
import './VSPage.css'
import { Board, Word } from '../../containers';
import Engine from '../../lib/Engine';
import { WID } from '../../constants/Language';
import { makePath } from '../../lib';

const PLAYER = {
  YOCHIYOCHI_NYANKO: 'yochiyochi',
  NORA_NYANKO: 'nora',
  OFFENCE_NYANKO: 'seme',
  DIFFENCE_NYANKO: 'mamori',
  BOSS_NYANKO: 'boss',
}

const playerLabelProps = [
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_1, value: PLAYER.YOCHIYOCHI_NYANKO },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_2, value: PLAYER.NORA_NYANKO },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_3, value: PLAYER.OFFENCE_NYANKO },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_4, value: PLAYER.DIFFENCE_NYANKO },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.PLAYER_LEVEL_5, value: PLAYER.BOSS_NYANKO },
]

const COLOR = {
  BLACKCAT: 'blackcat',
  WHITECAT: 'whitecat',
}

const colorLabelProps = [
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.TURN_FIRST, value: COLOR.BLACKCAT },
  { wid: WID.PLAY_PAGE.VS_CPU_PAGE.TURN_SECOND, value: COLOR.WHITECAT },
]

const stylesForSelectPlayerPage = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 10,
  },
  subtitle: {
    margin: `${theme.spacing.unit * 2}px 0`,
    color: theme.palette.common.white,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.3em',
    textAlign: 'center',
    width: '100%',
  },
  gridItem: {
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: theme.spacing.unit * 5,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 5px 0px 0px #00000022`,
    userSelect: 'none',
    margin: '0 auto',
    color: theme.palette.primary.main,
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow: 'none',
      transform: 'translate(0px,1px)',
    },
  },
  buttonLabel: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1em',
  }
})

class SelectPlayerPage extends React.Component {
  state = {
    player: PLAYER.YOCHIYOCHI_NYANKO,
    color: COLOR.BLACKCAT,
  }

  componentDidMount() {
    const { player, color } = this.props
    this.setState({ player, color })
  }

  handleClickPlayer = (value) => {
    this.setState({ player: value })
  }

  handleClickColor = (value) => {
    this.setState({ color: value })
  }

  handleClickStart = () => {
    const { onStart } = this.props
    const { player, color } = this.state

    if (onStart !== undefined) {
      onStart(player, color)
    }
  }

  render() {
    const { classes } = this.props
    const { player, color } = this.state

    var playerWid = playerLabelProps.find(props => props.value === player).wid

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={6} xs={12} className={classes.gridItem}>
            <Typography className={classes.subtitle}><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.SELECT_PLAYER}/></Typography>
            <RadioButtonGroup labelProps={playerLabelProps} selected={player} onClick={this.handleClickPlayer} center/>
          </Grid>
          <Grid item sm={6} xs={12} className={classes.gridItem}>
            <Typography className={classes.subtitle}><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.SELECT_TURN}/></Typography>
            <RadioButtonGroup labelProps={colorLabelProps} selected={color} onClick={this.handleClickColor} center/>
          </Grid>
        </Grid>
        <SelectionResult player={player} playerLabel={<Word wid={playerWid}/>} color={color}/>
        <div className={classes.button} onClick={this.handleClickStart}>
          <Typography className={classes.buttonLabel} color='inherit'><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.START}/></Typography>
        </div>
      </div>
    )
  }
}

SelectPlayerPage = withStyles(stylesForSelectPlayerPage, { withTheme: true })(SelectPlayerPage)

const stylesForSelectionResult = theme => ({
  svg: {
    marginBottom: theme.spacing.unit * 2
  },
  text: {
    fontSize: '40px',
    fill: '#fff',
    fontFamily: 'heisei-maru-gothic-std',
    textAnchor: 'middle',
    dominantBaseline: 'central',
  },
  cat: {
  }
})

class SelectionResult extends React.Component {
  render() {
    const { classes, theme, player, playerLabel, color } = this.props
    return (
      <svg className={`${classes.svg} selection-result`} viewBox='0 0 1000 520'>
        <def>
          <Cat type='blackcat'/>
          <Cat type='whitecat'/>
        </def>
        <text className={classes.text} transform="translate(250 50)"><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.MINE}/></text>
        <text className={classes.text} transform="translate(750 50)">{playerLabel}</text>
        <rect x='50'  y='100' rx='50' width='400' height='400' stroke='#fff' strokeMiterlimit='10' strokeWidth='15' fill={theme.palette.primary.main}/>
        <rect x='550' y='100' rx='50' width='400' height='400' stroke='#fff' strokeMiterlimit='10' strokeWidth='15' fill={theme.palette.primary.main}/>
        <use className='mine-cat' xlinkHref={`#${color}`} x={200} y={250} key={`mine-${color}`}/>
        <use className='enemy-cat' xlinkHref={`#${color === COLOR.BLACKCAT ? COLOR.WHITECAT : COLOR.BLACKCAT}`} x={700} y={250} key={`enemy-${color}-${player}`}/>
      </svg>
    )
  }
}

SelectionResult = withStyles(stylesForSelectionResult, { withTheme: true })(SelectionResult)

const stylesForVSPlayPage = theme => ({

})

const VS_PLAY_STATUS = {
  PLAYING: 'PLAYING',
  ENDING: 'ENDING',
  WATCHING: 'WATCHING'
}

const RESULT = {
  WIN: 'win',
  LOSE: 'lose'
}

const TURN = {
  MINE: 'mine',
  ENEMY: 'enemy'
}

class VSPlayPage extends React.Component {
  state = {
    status: VS_PLAY_STATUS.PLAYING,
    moves: [],
    calls: undefined,
    result: '',
    turn: '',
  }

  componentDidMount() {
    const { color } = this.props
    if (color === COLOR.WHITECAT) {
      this.setState({ moves: [{ x: 7, y: 7, color: COLOR.BLACKCAT }], turn: TURN.MINE })
    }
    else {
      this.setState({ turn: TURN.MINE })
    }
  }

  handleClickBoard = (x, y) => {
    const { moves } = this.state
    var color, engine, calls, status, newMoves, result, turn
    switch (this.state.status) {
      case VS_PLAY_STATUS.PLAYING:
        if (moves.some(move => move.x === x && move.y === y)) {
          return
        }
        color = moves.length % 2 === 0 ? COLOR.BLACKCAT : COLOR.WHITECAT
        if (color !== this.props.color) {
          return
        }
    
        engine = new Engine()
        engine.setMoves(moves, color)
        engine.checkLine()
        calls = engine.getCalls(x, y)
    
        newMoves = moves.concat({ x, y, color })
        if (engine.isFiveLine(x, y) === true) {
          status = VS_PLAY_STATUS.ENDING
          result = RESULT.WIN
          calls = []
          turn = ''
        }
        else {
          status = VS_PLAY_STATUS.PLAYING
          result = ''
          turn = TURN.ENEMY 
        }
        if (this.props.onMove && status === VS_PLAY_STATUS.PLAYING) {
          this.props.onMove(this.props.player, newMoves, this.handleCPUMove)
        }
        this.setState({
          status,
          moves: newMoves,
          calls,
          result,
          turn,
        })
        break
      case VS_PLAY_STATUS.ENDING:
        return
      case VS_PLAY_STATUS.WATCHING:
        break
      default:
        break
    }
  }

  handleCPUMove = (move) => {
    var engine, calls, status, result, turn
    engine = new Engine()
    engine.setMoves(this.state.moves, this.state.moves.length % 2 === 0 ? COLOR.BLACKCAT : COLOR.WHITECAT)
    engine.checkLine()
    calls = engine.getCalls(move.x, move.y)

    if (engine.isFiveLine(move.x, move.y) === true) {
      status = VS_PLAY_STATUS.ENDING
      result = RESULT.LOSE
      turn = ''
    }
    else {
      status = VS_PLAY_STATUS.PLAYING
      result = ''
      turn = TURN.MINE
    }
    this.setState({
      status: status,
      moves: this.state.moves.concat(move),
      calls,
      result,
      turn,
    })
  }

  handleRetry = () => {
    this.setState({
      status: VS_PLAY_STATUS.PLAYING,
      moves: this.props.color === COLOR.WHITECAT ? [{ x: 7, y: 7, color: COLOR.BLACKCAT }] : [],
      calls: [],
      result: '',
      turn: this.props.color === COLOR.BLACKCAT ? TURN.MINE : TURN.ENEMY,
    })
  }

  handleSelect = () => {
    const { onSelect } = this.props
    this.setState({
      status: VS_PLAY_STATUS.PLAYING,
      moves: [],
      calls: [],
      result: '',
      turn: '',
    })
    if (onSelect) {
      onSelect()
    }
  }

  handleWatch = () => {
    this.setState({
      status: VS_PLAY_STATUS.WATCHING,
      history: this.state.moves
    })
  }

  render() {
    const { player, color } = this.props
    const { moves, calls, status, result, turn } = this.state
    var afterComponent

    if (status === VS_PLAY_STATUS.ENDING) {
      afterComponent = <ResultSVG
        color={color}
        player={player}
        result={result}
        onClickRetry={this.handleRetry}
        onClickSelect={this.handleSelect}
        onClickWatch={this.handleWatch}
      />
    }

    return (
      <React.Fragment>
        <PlayerName player={player} color={color} turn={turn} moves={moves}/>
        <Board
          onClick={this.handleClickBoard}
          moves={moves}
          calls={calls}
          afterComponent={afterComponent}
        />
      </React.Fragment>
    )
  }
}

VSPlayPage = withStyles(stylesForVSPlayPage, { withTheme: true })(VSPlayPage)

const stylesForResultSVG = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  buttonLabel: {
  },
})

class ResultSVG extends React.Component {
  render() {
    const { classes, theme, color, player, result, onClickRetry, onClickSelect, onClickWatch } = this.props
    var wid = playerLabelProps.find(props => props.value === player).wid
    return (
      <svg className={`result-svg ${classes.root}`} viewBox='0 0 1500 1500'>
        <def>
          <g id='win'>
            <path d='M479.62,54.45a54.76,54.76,0,0,0-1.2-12.79C475.48,27,463.46,10,439,10c-21.53,0-34.93,13.5-39.51,28a30.54,30.54,0,0,0-27.3-16.6c-12,0-27.34,7.48-31.5,28.54a73.6,73.6,0,0,0-1.34,8.64c-29.39-33.74-37.78-37.18-50.11-37.18-15.76,0-28.39,11.5-31.54,28.66l-.06.3c0-.1,0-.21,0-.3-3.87-21.53-19.62-29.17-31.93-29.17-10.61,0-22.91,5.38-29.09,19.79a32,32,0,0,0-21.47-17.59,28.11,28.11,0,0,0-7.75-.84c-14.28,0-22.87,7.63-28.36,16.45a44.3,44.3,0,0,0-5.69-6.9c-6.09-6.34-13.91-9.55-23.24-9.55-11.67,0-18.24,4.93-22.66,9.3a41,41,0,0,0-6,7.28c-5.47-8.88-14-16.58-28.28-16.58a29.35,29.35,0,0,0-7.67.81C32.88,26.2,19.85,37,20.56,58.18v.49l.19,1.92c.31,3.2.7,7.18,1.5,12,1.47,9.58,4,21.84,7.7,37.48l.08.31c2.7,10.41,6.69,25.1,10.29,35.9C43,154.36,46.55,165.39,54.72,173l.2.18A32.59,32.59,0,0,0,77,181.81c18.43,0,27.64-12.9,33.22-24.38C116,169,125,181.81,143.33,181.81A33,33,0,0,0,165.84,173c7.38-6.91,10.6-16.25,13.44-24.5l.64-1.85c2.94-8.37,6.21-19.94,10.27-36.4l0-.13c.19-.78.37-1.54.54-2.31.16,10.95.67,21,1.1,29.44l.1,1.89.08.61c.28,2.09.49,4,.69,5.77.3,2.68.56,5,.93,7.09l.07.37c4.31,21.27,19.29,28.85,31.88,28.85,12.23,0,27.86-7.55,31.86-28.83,0,.29.1.59.16.87,3.92,20.87,18.87,28.31,31.56,28.31,12.39,0,27.17-7.38,31.51-28.08l.08-.42.28-1.79c.28-1.86.61-4,.9-6.47,29.23,33.49,37.88,37.1,49.84,37.1,14.17,0,26.24-9.66,30.74-24A36,36,0,1,0,465,133.55,45.14,45.14,0,0,0,470.7,123a61.58,61.58,0,0,0,3.45-11.81c2-7.83,3.23-17.29,4.52-27.92l0-.25,0-.26A228.1,228.1,0,0,0,479.62,54.45Zm-74.73,90.84c.18-1.8.33-3.78.42-6,.48-4.64.86-10.09,1.12-16.1l0,.07a42.75,42.75,0,0,0,5.64,10.31A36.38,36.38,0,0,0,404.89,145.29Z' transform='translate(-20.53 -10.04)' fill='#fff' />
            <path d='M171.21,37.62a14,14,0,0,0-3.91-.34c-13.77,0-18.36,13.26-22.27,25-1.19,3.57-2.72,8-3.91,12.92a1.13,1.13,0,0,1-2,0c-1.87-5.44-3.91-10.2-5.78-14.45-2.89-7-6-13.77-10.71-18.36-2.72-2.89-6.29-5.1-12.58-5.1s-9.35,2.21-12.24,5.1c-4.59,4.25-7.65,11.39-10.88,18.36-1.87,4.25-3.57,9-5.61,14.45a1.28,1.28,0,0,1-2.21,0c-1.19-4.93-2.72-9.35-3.91-12.92-3.91-11.73-8.33-25-22.1-25a15.22,15.22,0,0,0-3.91.34c-4.25,1-14.11,4.76-13.6,20.06.34,3.4.68,7.48,1.53,12.58,1.53,10,4.25,22.78,7.48,36.38,3.4,13.09,7,25.84,10,35,2.55,7.81,5.27,15.63,10.37,20.39A17.66,17.66,0,0,0,77,166.81c12.92,0,18-11.39,23.12-23.62,2.55-6.29,5.95-14.45,9-23.12a1.13,1.13,0,0,1,2,0c3.23,8.67,6.29,16.83,9,23.12,5.44,12.23,10.2,23.62,23.12,23.62a18.08,18.08,0,0,0,12.24-4.76c5.1-4.76,7.48-12.58,10.2-20.39,3.23-9.18,6.63-21.93,9.86-35,3.23-13.6,6-26.35,7.48-36.38a83,83,0,0,0,1.53-12.58C185.32,42.38,175.29,38.64,171.21,37.62Z' transform='translate(-20.53 -10.04)' fill='#e4007f' />
            <path d='M242.78,52.75c-2.72-15.13-12.92-16.83-17.17-16.83s-14.11,1.7-17.17,16.83c-.51,2.89-.85,7.14-1.53,12.24-.51,10-1.19,22.78-1.19,36.38s.68,26.34,1.19,36.38c.68,5.09,1,9.34,1.53,12.24,3.06,15.13,12.92,16.82,17.17,16.82s14.45-1.69,17.17-16.82a113.3,113.3,0,0,0,1.53-12.24c.85-10,1.19-22.78,1.19-36.38S245.16,75,244.31,65A112.63,112.63,0,0,0,242.78,52.75Z' transform='translate(-20.53 -10.04)' fill='#e4007f' />
            <path d='M389,53.09C385.91,38,376.22,36.43,372.14,36.43c-3.91,0-13.94,1.53-16.83,16.66a81.64,81.64,0,0,0-1.53,12.24c-.68,7.82-1.19,18.36-1.19,29.41,0,.85-1.19,1.19-1.87.68a62.09,62.09,0,0,0-5.09-6.12c-43.87-52.87-49.47-52.87-56.44-52.87-9.69,0-15.3,7.82-16.83,16.66-.68,2.89-1.19,7.14-1.87,12.24-.51,9.86-1.2,22.78-1.2,36.72,0,13.43.69,26.69,1.2,36.2.68,5.11,1.19,9.36,1.87,12.75,2.72,14.63,12.58,16.16,16.83,16.16,3.9,0,13.77-1.53,16.82-16.16a125,125,0,0,0,1.53-12.75c.51-8,1.2-18,1.2-29.06,0-1.19,1.18-1.53,1.69-.68a62.24,62.24,0,0,1,5.11,6.12c43.85,52.87,49.63,52.87,56.26,52.87,9.52,0,15.64-8.17,17.17-16.5a97.21,97.21,0,0,0,1.36-12.75c1-9.51,1.53-22.77,1.53-36.2,0-13.94-.51-26.86-1.53-36.72A85.48,85.48,0,0,0,389,53.09Z' transform='translate(-20.53 -10.04)' fill='#e4007f' />
            <path d='M464.62,54.79a40.86,40.86,0,0,0-.85-9.86C462.92,40.34,458.67,25,439,25c-16.32,0-24,10.88-25.84,19.89-.34,2.55-.68,6-.85,9.86a259.57,259.57,0,0,0,1.19,26.69,219.7,219.7,0,0,0,3.91,26.35,37.54,37.54,0,0,0,2.89,9.52c3.57,9.69,9.86,14.44,18.36,14.44,8.33,0,14.45-4.75,18.19-14.44a47.54,47.54,0,0,0,2.72-9.52c1.87-7.14,3.06-16.49,4.25-26.35A212.67,212.67,0,0,0,464.62,54.79Z' transform='translate(-20.53 -10.04)' fill='#e4007f' />
            <path d='M438.61,136.9a21.16,21.16,0,1,0,20.91,21.24A21.11,21.11,0,0,0,438.61,136.9Z' transform='translate(-20.53 -10.04)' fill='#e4007f' />
          </g>
          <g id='lose'>
            <path d='M484.25,121.79a31.36,31.36,0,0,0,7.61-20.42,30.41,30.41,0,0,0-7.15-19.76c6.81-4.78,12.25-12.67,12.25-26,0-11.07-6.36-25.79-24.27-30.91-8.49-2.47-27.84-3.81-35.44-3.81a223,223,0,0,0-23.92,1.28l-.42.05c-17.62,2.45-30.77,12.86-35.2,27.58a39.84,39.84,0,0,0-3-5.29l-.06-.09C364.9,30.15,344.62,20.92,323,20.92c-15.09,0-29.39,5-40.27,14a53.14,53.14,0,0,0-18.87,34.23,76.07,76.07,0,0,0-17.46-27.48c-13.3-13.3-33.71-21.56-53.28-21.56-19.92,0-40.34,8.27-53.32,21.61-15,14.84-23.21,36.27-23.21,60.37a96.09,96.09,0,0,0,3.77,27c-3.78-5.33-9.58-10-18-12.85l-.82-.27-2.24-.49c-2.15-.48-4.82-1.07-8.16-1.56-4.24-.66-10.47-.85-18.63-1,.37-21.64.08-44.57-2.78-62.41-3-21.86-19-29.62-31.84-29.62S10,28.49,6.06,49.72A92.14,92.14,0,0,0,4.27,63.47C3.45,72.58,3,84.7,3,99.5s.41,27.12,1.23,36.39c.41,5.63.81,10.3,1.94,14.52,3.89,16.56,17.31,27.92,36,30.42a188.69,188.69,0,0,0,24.15,1.33,187.51,187.51,0,0,0,24.61-1.33l.4-.05.72-.14c3.75-.71,6.71-1.28,9.67-2.12l.25-.07.25-.09c17.68-5.73,24-19.82,24-31.1a29.77,29.77,0,0,0-.28-4,73.74,73.74,0,0,0,13.89,19.2c13,13.34,33.4,21.61,53.32,21.61,19.56,0,40-8.26,53.28-21.56a74.34,74.34,0,0,0,12.66-17,49,49,0,0,0,8,15.18c12.12,15.4,30.59,23.53,53.4,23.53,31.47,0,50.24-14.51,58.1-32.85,5.7,16.07,17.53,26.41,33.82,29.06,6.3,1.2,14.21,1.76,24.87,1.76a185.13,185.13,0,0,0,24.36-1.63,42.55,42.55,0,0,0,12.13-2.69c17.1-5.5,23.22-19.42,23.22-30.75A32,32,0,0,0,484.25,121.79Zm-286.58-7.92-.18.18a5.54,5.54,0,0,1-3.85,1.22,9.73,9.73,0,0,1-5.2-1.22c-1.55-1.56-3.14-5.39-2.92-11.48v-1c-.22-6.09,1.37-9.92,2.89-11.45a9.58,9.58,0,0,1,5.23-1.24,5.34,5.34,0,0,1,3.6,1l.21.22.22.21c1.4,1.36,2.9,5.18,2.9,11.83S199.07,112.51,197.67,113.87Zm71.44-3.81c.18-2.64.27-5.31.23-8,0-.81,0-1.61,0-2.42a55.22,55.22,0,0,0,4.81,7.27A30.3,30.3,0,0,0,269.11,110.06Zm96.17-20.92a26.57,26.57,0,0,0,5.54-3.85,29.09,29.09,0,0,0,3.42-3.66c-.19,5.68-.25,11.58-.26,17.43A54,54,0,0,0,365.28,89.14Z' transform='translate(-3.04 -20.07)' fill='#fff' />
            <path d='M97.6,130.44c-2.38-.52-5.1-1.19-8.67-1.7-6.29-1-24-.68-28.9-1-1.53-.17-2.72-1.18-2.72-3.73C58,97.8,58,71.62,54.93,52.75c-2-15.3-12.92-16.83-17-16.83-4.25,0-14.45,1.53-17.17,16.83a76.23,76.23,0,0,0-1.53,11.9C18.38,74,18,86.41,18,99.5c0,13.26.34,25.67,1.19,35.19.34,4.76.68,9,1.53,12.06,2,9,9.35,17.34,23.46,19.21a169.61,169.61,0,0,0,22.1,1.2A176.19,176.19,0,0,0,88.93,166c3.57-.67,6.29-1.19,8.67-1.87,12.58-4.08,13.6-13.76,13.6-16.83C111.2,144.38,110.18,134.69,97.6,130.44Z' transform='translate(-3.04 -20.07)' fill='#00a0e9' />
            <path d='M193.13,35.07c-15.64,0-32.46,6.63-42.67,17.17-12.4,12.24-18.86,29.92-18.86,49.81s6.46,37.57,18.86,49.81c10.21,10.53,27,17.16,42.67,17.16s32.13-6.63,42.67-17.16c12.24-12.24,18.87-29.92,18.53-49.81.34-19.89-6.29-37.57-18.53-49.81C225.26,41.7,208.77,35.07,193.13,35.07Zm15,89.59a20.15,20.15,0,0,1-14.45,5.61c-6.29,0-12.24-2-15.81-5.61-4.93-4.93-7.65-12.92-7.31-22.61-.34-9.69,2.38-17.68,7.31-22.61,3.57-3.57,9.52-5.61,15.81-5.61,5.78,0,10.88,1.87,14.45,5.61,5.1,4.93,7.48,12.92,7.48,22.61S213.19,119.73,208.09,124.66Z' transform='translate(-3.04 -20.07)' fill='#00a0e9' />
            <path d='M349,95.59c-4.76-3.06-10.54-5.44-16-7.82-8.84-3.57-16.49-7-16.49-11.73,0-3.23,4.59-6.29,9.69-6.29,5.95,0,9.86,2.38,13.77,4.25,3.23,1.7,7,3.57,11.39,3.57a14.38,14.38,0,0,0,9.35-3.4c4.42-3.91,7.82-11.9,1.53-21.25-7-10.2-22.61-17-39.27-17-22.27,0-44.71,14.28-44.71,41.31,0,11.39,6.63,22.44,20.4,32.47,5.27,3.57,11.39,6,17,8,7.82,3.06,13.77,5.44,13.77,10.2,0,3.57-3.91,5.94-9.52,5.94a21.51,21.51,0,0,1-4.76-.5c-7-1.2-10.54-4.76-14.11-8.16-3.91-3.57-7-6.63-12.58-6.63a16,16,0,0,0-12.92,6.12c-3.06,4.08-3.91,9.86-2.38,15.8a34.76,34.76,0,0,0,5.61,10.88c9.35,11.91,23.46,17.85,41.65,17.85,35.19,0,47.43-21.24,47.43-39.27C367.89,115.31,361.94,103.92,349,95.59Z' transform='translate(-3.04 -20.07)' fill='#00a0e9' />
            <path d='M468.53,130.44a53.79,53.79,0,0,0-8.67-2,280.28,280.28,0,0,0-30.77-.86c-2.89,0-2.89-7,.34-7,8.5,0,21.08-.34,26.69-1.19,13.6-2,20.74-8.16,20.74-18,0-9.69-7.14-16-20.74-17.68-5.61-1-19.55-1.53-26.69-1.19-2.72,0-3.06-7-.34-7s24.65-.34,30.77-1.19c15-2,22.1-6,22.1-18.7,0-2.55-.85-12.92-13.43-16.49-5.78-1.7-22.95-3.23-31.28-3.23A209.62,209.62,0,0,0,415,37.11c-13.43,1.87-21.59,9.52-23.46,19.21a74.53,74.53,0,0,0-1.36,11.39c-1,9.35-1.19,21.08-1.19,33.66S389,126,390.16,135c2.38,20.73,12.92,28.72,24.82,30.6,6.12,1.18,14.11,1.53,22.27,1.53a172.7,172.7,0,0,0,22.61-1.53,27.44,27.44,0,0,0,8.67-1.88C481.11,160,482,150,482,147.09,482,144.38,481.11,134.34,468.53,130.44Z' transform='translate(-3.04 -20.07)' fill='#00a0e9' />
          </g>
        </def>
        <rect className={'result-background'} x='-50' y='450' width='1600' height='600' fill={'#8fc31fcc'} stroke='#fff' strokeMiterlimit='10' strokeWidth='20'/>
        <use className={'result-cat'} xlinkHref={`#${color}`} x={-100} y={500}/>
        {result === 'win' ? (
          <React.Fragment>
            <use className={'fukidashi'} xlinkHref={`#nyanko`} x={630} y={530}/>
            <use className={'win'} xlinkHref={`#win`} x='750' y='650'/>
          </React.Fragment>
        ) : result === 'lose' ? (
          <use className={'lose'} xlinkHref={`#lose`} x='750' y='650'/>
        ) : undefined} 
        <use className={'enemy-cat'} xlinkHref={`#${color === COLOR.BLACKCAT ? COLOR.WHITECAT : COLOR.BLACKCAT}`} x={900} y={925} key={`enemy-${color}`}/>
        <text className={`vs`} x='800' y='975' fill={theme.palette.common.white}>VS</text>
        <text className={`enemy-name`} x='1200' y='975' fill={theme.palette.common.white}><Word wid={wid}/></text>
        <rect className={'button'} x='100' y='1200' rx='50' width='600' height='100' fill={'#ffffff'} onClick={onClickRetry}/>
        <text className={`button-label`} x='400' y='1250' fill={theme.palette.primary.main} onClick={onClickRetry}><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.RESULT_DIALOG.RETRY}/></text>
        <rect className={'button'} x='800' y='1200' rx='50' width='600' height='100' fill={'#ffffff'} onClick={onClickSelect}/>
        <text className={`button-label`} x='1100' y='1250' fill={theme.palette.primary.main} onClick={onClickSelect}><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.RESULT_DIALOG.SELECT_PLAYER}/></text>
        <rect className={'button'} x='100' y='1350' rx='50' width='600' height='100' fill={'#ffffff'} onClick={onClickWatch}/>
        <text className={`button-label`} x='400' y='1400' fill={theme.palette.primary.main} onClick={onClickWatch}><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.RESULT_DIALOG.WATCH_BOARD}/></text>
      </svg>
    )
  }
}

ResultSVG = withStyles(stylesForResultSVG, { withTheme: true })(ResultSVG)


const stylesForPlayerName = theme => ({
  text: {
    fontSize: '40px',
    fill: '#fff',
    fontFamily: 'heisei-maru-gothic-std',
    textAnchor: 'middle',
    dominantBaseline: 'central',
  },
  cat: {
  }
})

class PlayerName extends React.Component {
  render() {
    const { classes, theme, player, color, turn, moves } = this.props
    var wid = playerLabelProps.find(props => props.value === player).wid

    return (
      <svg className={`vs-player-name`} viewBox='0 0 1500 120'>
        <def>
          <Cat type='blackcat'/>
          <Cat type='whitecat'/>
        </def>
        <text className={classes.text} transform="translate(300 60)"><Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.MINE}/></text>
        {turn === TURN.MINE ? (
          <rect className={'turn-line turn-line-mine'} x='200' y='110' rx='5' width='200' height='10' fill={theme.palette.common.white} key={`turn-line-mine-${moves.length}`}/>
        ) : turn === TURN.ENEMY ? (
          <rect className={'turn-line turn-line-enemy'} x='1100' y='110' rx='5' width='200' height='10' fill={theme.palette.common.white} key={`turn-line-enemy-${moves.length}`}/>
        ) : undefined }
        <text className={classes.text} transform="translate(1200 60)"><Word wid={wid}/></text>
        <use className='mine-cat' xlinkHref={`#${color}`} x={550} y={10} key={`mine-${color}`}/>
        <use className='enemy-cat' xlinkHref={`#${color === COLOR.BLACKCAT ? COLOR.WHITECAT : COLOR.BLACKCAT}`} x={850} y={10} key={`enemy-${color}-${player}`}/>
      </svg>
    )
  }
}

PlayerName = withStyles(stylesForPlayerName, { withTheme: true })(PlayerName)


const VS_CPU_STATUS = {
  SELECT_PLAYER: 'SELECT_PLAYER',
  PLAYING: 'PLAYING',
}

const styles = theme => ({
})

class VSPage extends React.Component {
  state = {
    status: VS_CPU_STATUS.SELECT_PLAYER,
    player: PLAYER.YOCHIYOCHI_NYANKO,
    color: COLOR.BLACKCAT,
  }

  handleStart = (player, color) => {
    this.setState({ status: VS_CPU_STATUS.PLAYING, player, color })
  }

  handleSelect = () => {
    this.setState({ status: VS_CPU_STATUS.SELECT_PLAYER })
  }

  handleBack = () => {
    this.setState({ status: VS_CPU_STATUS.SELECT_PLAYER })
  }

  render() {
    const { status, player, color } = this.state
    const { handleMove, language } = this.props
    return (
      <React.Fragment>
        {status === VS_CPU_STATUS.SELECT_PLAYER ? (
          <React.Fragment>
            <PlayNavigation title={<Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.TITLE}/>} back={makePath('/play', language)}/>
            <SelectPlayerPage onStart={this.handleStart} player={player} color={color}/>
          </React.Fragment>
        ) : status === VS_CPU_STATUS.PLAYING ? (
          <React.Fragment>
            <PlayNavigation title={<Word wid={WID.PLAY_PAGE.VS_CPU_PAGE.TITLE}/>} onClick={this.handleBack}/>
            <VSPlayPage player={player} color={color} onMove={handleMove} onSelect={this.handleSelect}/>
          </React.Fragment>
        ) : undefined}
      </React.Fragment>
		)
  }
}

export default withStyles(styles, { withTheme: true })(VSPage)
