import { connect } from 'react-redux'
import { GlobalNavigation } from '../../components'
import * as actions from '../../actions'
import LocalDB from '../../lib/LocalDB';
import { PROFILE_SYNC_STATUS, ERROR_USER_MANAGER } from '../../constants';


const mapStateToProps = (state, ownProps) => {
  const { width } = state.window
  const { language } = state.settings
  return ({
    pathname: state.routing.location !== null ? state.routing.location.pathname : undefined,
    width,
    language,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    handleChangedLanguage: (value) => {
      dispatch(actions.settings.changedLanguage(value))
    },
    handleDidMount: () => {
      LocalDB.initialize()
      .then((event) => {
        return LocalDB.getToken()
      })
      .then(token => {
        if (token !== undefined && token !== '') {
          dispatch(actions.user.fetchProfile(token))
          .then(response => {
            if (response.success === false && response.error === ERROR_USER_MANAGER.JWT_VERIFY_FAILED) {
              LocalDB.setToken('')
            }
          })
        }
        else {
          dispatch(actions.user.changedSyncProfileStatus(PROFILE_SYNC_STATUS.FINISH))
        }
      })
    },
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalNavigation)
