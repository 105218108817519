import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, ButtonBase, Button, Fade, Fab } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { KeyboardArrowLeftRounded as LeftIcon, KeyboardArrowRightRounded as RightIcon } from '@material-ui/icons'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { CatPiece as CatPieceIcon } from '../Icons'
import { GoodsCard } from '../Common'
import { Word } from '../../containers';
import { WID } from '../../constants/Language';
import { makePath } from '../../lib';

const styles = theme => ({
  section: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: theme.spacing.unit * 4,
  },
  content: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
  },
  card: {
    backgroundColor: 'inherit',
    boxShadow: 'none',
    borderRadius: 0,
  },
  titleGridBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 5}px 0`,
  },
  sectionTitle: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
  },
  icon: {
    marginRight: theme.spacing.unit * 2
  },
  sliderBox: {
    position: 'relative',
  },
  slideControlerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 200 / 2 - theme.spacing.unit * 3,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    margin: `0 ${theme.spacing.unit * 3}px`,
  },
  goodsCardBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 8,
  },
  moreButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing.unit * 4,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    boxShadow: `2px 2px 0px 2px ${theme.palette.grey[300]}`,
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    maxWidth: 200,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
    }
  }
})

class GoodsSecion extends React.Component {
  state = {
    fetched: false,
    visibleSection: false,
  }
  _isMounted = false
  
  componentWillMount() {
    this.props.fetchTopics((result) => {
      if (this._isMounted === false) {
        return
      }
      this.setState({ fetched: true })
    })
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (wd, event) => {
    var elem, rect
    if (this._isMounted === false) {
      return
    }
    elem = document.getElementById('goods-section')
    if (elem === null) {
      return
    }
    rect = elem.getBoundingClientRect()
    if (this.state.visibleSection === false && rect.height * 0.8 - (window.innerHeight - rect.top) < 0) {
      this.setState({ visibleSection: true })
    }
  }

  handlePrevious = () => {
    this.slider.slickPrev()
  }

  handleNext = () => {
    this.slider.slickNext()
  }

  render() {
    const { classes, width, posts, language } = this.props
    const { visibleSection } = this.state

    return (
      <div className={classes.section} id='goods-section'>
        <Fade in={visibleSection} timeout={1000}>
          <div>
            <div className={classes.titleGridBox}>
              <div className={classes.icon}>
                <CatPieceIcon type='black' width={50}/>
              </div>
              <Typography variant='h6' className={classes.sectionTitle}><Word wid={WID.HOME_PAGE.GOODS.TITLE}/></Typography>
            </div>
            {posts !== undefined && Math.ceil(width / 316) <= posts.length ? (
              <div className={classes.sliderBox}>
                <Slider
                  ref={component => this.slider = component}
                  dots={true}
                  infinite={true}
                  arrows={false}
                  autoplay={true}
                  speed={500}
                  slidesToShow={Math.ceil(width / 316) > posts.length ? posts.length : Math.ceil(width / 316) - 1}
                  slidesToScroll={1}
                >
                  {posts.map(post =>
                    <ButtonBase key={post.id} component={Link} to={makePath(`/goods/${post.slug}`, language)}>
                      <GoodsCard post={post}/>
                    </ButtonBase>
                  )}
                </Slider>
                <div className={classes.slideControlerBox}>
                  <Fab className={classes.button} onClick={this.handlePrevious}><LeftIcon/></Fab>
                  <Fab className={classes.button} onClick={this.handleNext}><RightIcon/></Fab>
                </div>
              </div>
            ) : posts !== undefined ? (
              <div className={classes.goodsCardBox}>
                {posts.map(post =>
                  <div key={post.id}>
                    <GoodsCard post={post}/>
                  </div>
                )}
              </div>
            ) : (<div></div>)}
            <div className={classes.moreButtonBox}>
              <Button variant='outlined' className={classes.moreButton} component={Link} to={makePath('/goods', language)}>
                <Word wid={WID.HOME_PAGE.GOODS.MORE}/>
              </Button>
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(GoodsSecion)
