import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Fade, Card, CardMedia, CardContent, Typography } from "@material-ui/core"

const stylesForGoodsCard = theme => ({
    card: {
      boxShadow: 'none',
      borderRadius: 0,
      maxWidth: 300,
      margin: `0 auto`,
    },
    cardMedia: {
      height: 200,
      objectFit: 'contain',
    },
    cardNonMedia: {
      height: 200,
      width: '100%',
      backgroundColor: theme.palette.grey[200],
    },
    cardContent: {
      textAlign: 'center',
    },
    name: {
      fontFamily: 'heisei-maru-gothic-std, sans-serif',
      fontStyle: 'normal',
    },
  })
  
class GoodsCard extends React.Component {
  state = {
    loaded: false
  }
  
  handleLoadedImage = (event) => {
    this.setState({ loaded: true })
  }

  render() {
    const { classes, post } = this.props
    const { loaded } = this.state
    var visibleCardMedia = Boolean(
      post._embedded !== undefined &&
      post._embedded['wp:featuredmedia'] !== undefined &&
      post._embedded['wp:featuredmedia'].length > 0 &&
      post._embedded['wp:featuredmedia'][0].source_url !== undefined
    )

    return (
      <Fade in={loaded}>
        <Card className={classes.card}>
          {visibleCardMedia ? (
            <CardMedia
              className={classes.cardMedia}
              image={post._embedded['wp:featuredmedia'][0].source_url}
              component='img'
              onLoad={this.handleLoadedImage}
            />
          ) : (
            <div className={classes.cardNonMedia}/>
          )}
          <CardContent className={classes.cardContent}>
            <Typography variant='body1' className={classes.name}>{post.meta.name}</Typography>
          </CardContent>
        </Card>
      </Fade>
    )
  }
}
  
export default withStyles(stylesForGoodsCard, { withTheme: true })(GoodsCard)