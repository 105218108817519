import { connect } from 'react-redux'
import { UserCard } from '../../components'
import * as actions from '../../actions'
import LocalDB from '../../lib/LocalDB';

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  const { language } = state.settings
  return ({
    width,
    height,
    language,
    user: state.user,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    handleClickRegister: (name) => {
      return new Promise((resolve, reject) => {
        dispatch(actions.user.fetchRegister(name))
        .then(response => {
          if (response.success === true) {
            LocalDB.setToken(response.token)
            dispatch(actions.user.fetchProfile(response.token))
          }
          return resolve(response)
        })
      })
    }
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserCard)
