import { types } from '../constants/ActionTypes'
import { LANGUAGE_SHORT_CODE, PROFILE_SYNC_STATUS } from '../constants';
import { getLanguageShortCode } from '../lib';

const initialState = {
  language: LANGUAGE_SHORT_CODE.JA,
  profile: {
    name: '',
    syncStatus: PROFILE_SYNC_STATUS.NONE,
  }
}

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.router.LOCATION_CHANGE:
      return { ...state, language: getLanguageShortCode(action.payload.pathname) }
    case types.settings.CHANGED_LANGUAGE:
      return { ...state, language: action.language }
    default:
      return state
  }
}

export default settings