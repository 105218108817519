import React from 'react'
import { withRouter } from 'react-router'
import { multipleWordsProps } from '../../constants/Language';
  
class Word extends React.Component {
  render() {
    const { shortCode, wid } = this.props
    var wordProps = multipleWordsProps.find(props => props.wid === wid)
    var word = (wordProps === undefined || Boolean(wordProps[shortCode]) === false) ? ' ' : wordProps[shortCode]
    return (
      <React.Fragment>{word}</React.Fragment>
    )
  }
}

export default withRouter(Word)