import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core';
import { Word } from '../../containers';
import { WID } from '../../constants/Language';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 13}px 0 ${theme.spacing.unit * 5}px 0`,
    marginBottom: theme.spacing.unit * 5,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px 4px 4px #0001',
  },
  titleBox: {
    maxWidth: 1200,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  title: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
    fontStyle: 'normal',
    color: theme.palette.common.white,
  },
  icon: {
    marginRight: theme.spacing.unit * 2
  },
  content: {
    width: `calc(100% - ${theme.spacing.unit * 2}px)`,
    maxWidth: 1200,
    margin: '0 auto',
  },
})

class TopicsHeader extends React.Component {
  render() {
    const { classes, children } = this.props

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.titleBox}>
            <Typography variant='h6' className={classes.title}><Word wid={WID.TOPICS_PAGE.HEADER}/></Typography>
          </div>
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles, { withTheme: true })(TopicsHeader)
