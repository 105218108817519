import React from 'react'

class Cat extends React.Component {
  render() {
    const { width, height, rotate, type, margin } = this.props
    const styles = {
      svg: {
        margin: margin,
        transform: rotate ? `rotate(${rotate}deg)` : undefined
      },
      cls1 : {
        fill: type === 'black' ? '#000000' :
              type === 'white' ? '#ffffff' : undefined
      },
      cls2: {
        fill: type === 'black' ? '#ffffff' :
              type === 'white' ? '#4d4d4d' : undefined
      }
    }
    return (
      <svg style={styles.svg} width={width} height={height} viewBox='0 0 95 92.76'>
        <path style={styles.cls1} d='M91.036,31.8c1.724-3.994,2.217-9.584,2.217-13.454,0-6.645-.589-15.509-5-14.666-7.114,1.358-14.511,4.213-19.484,8.392a47.536,47.536,0,0,0-37.841.138c-4.972-4.253-12.446-7.158-19.636-8.53-4.412-.843-5,8.021-5,14.666,0,4.017.532,9.885,2.418,13.9A47.267,47.267,0,0,0,2.5,55.709C2.5,81.943,23.766,96.38,50,96.38S97.5,81.943,97.5,55.709A47.27,47.27,0,0,0,91.036,31.8Z' transform='translate(-2.5 -3.62)' />
        <circle style={styles.cls2} cx='26.846' cy='49.123' r='3.562' />
        <circle style={styles.cls2} cx='67.696' cy='49.123' r='3.562' />
        <path style={styles.cls2} d='M57.385,71.357c-1.614,1.23-2.943.954-4.293-.479a10.019,10.019,0,0,1-1.759-2.648c-.007-.017-.018-.029-.026-.045V65.09c0-.013,0-.024,0-.037a3.2,3.2,0,0,0,1.623-2.776c0-1.774-1.365-2.316-3.139-2.316S46.5,60.5,46.5,62.277a3.2,3.2,0,0,0,1.717,2.828v3.429a1.667,1.667,0,0,0,.024.24,9.8,9.8,0,0,1-1.483,2.1c-1.349,1.435-2.679,1.709-4.293.479-1.58-1.2-3.12,1.477-1.558,2.666,3.349,2.552,6.852.744,9.017-2.132,2.164,2.876,5.668,4.684,9.017,2.132a1.6,1.6,0,0,0,.554-2.112A1.553,1.553,0,0,0,57.385,71.357Z' transform='translate(-2.5 -3.62)' />
        <path style={styles.cls2} d='M89.413,69.509l-12.071-1c-1.98-.164-1.97,2.924,0,3.087l12.071,1C91.393,72.76,91.382,69.672,89.413,69.509Z' transform='translate(-2.5 -3.62)' />
        <path style={styles.cls2} d='M77.357,66.817,89.4,65.5c1.954-.214,1.976-3.3,0-3.088L77.357,63.729C75.4,63.943,75.381,67.032,77.357,66.817Z' transform='translate(-2.5 -3.62)' />
        <path style={styles.cls2} d='M22.2,68.51l-12.072,1c-1.966.163-1.983,3.252,0,3.087l12.072-1C24.167,71.434,24.184,68.345,22.2,68.51Z' transform='translate(-2.5 -3.62)' />
        <path style={styles.cls2} d='M10.144,65.5l12.041,1.315c1.973.215,1.958-2.874,0-3.088L10.144,62.414C8.172,62.2,8.187,65.288,10.144,65.5Z' transform='translate(-2.5 -3.62)' />
      </svg>
    )
  }
}

export default Cat