import { connect } from 'react-redux'
import { GoodsPage } from '../../components'
import * as actions from '../../actions'
import { POST_TYPES } from '../../constants';

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  var post = state.wordpress.posts.find(post =>
    post.type === POST_TYPES.GOODS &&
    decodeURIComponent(post.slug) === ownProps.match.params.slug
  )
  return ({
    width,
    height,
    post
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    mount: (callback) => {
      dispatch(actions.wordpress.fetchCustomPosts(POST_TYPES.GOODS, `?slug=${ownProps.match.params.slug}&_embed`))
      .then(response => {
        if (response.length !== 1) {
          return Promise.reject('error')
        }
        if (
          response[0]._links !== undefined &&
          response[0]._links['wp:attachment'] !== undefined &&
          response[0]._links['wp:attachment'].length > 0 &&
          response[0]._links['wp:attachment'][0].href !== undefined
        ) {
          return dispatch(actions.wordpress.fetchMedia(response[0]._links['wp:attachment'][0].href))
        }
      })
      .then(response => {
        if (callback !== undefined) {
          callback(response)
        }
      })
    }
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoodsPage)
